import React from 'react';
import { Section } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { usePlansItems } from '@atlassian/jira-plans-item-store';
import { MenuItem } from '../../../../common/ui/menu/item';
import messages from './messages';
import type { AdminFooterProps } from './types';

export const AdminFooter = ({ testIdPrefix }: AdminFooterProps) => {
	const { formatMessage } = useIntl();
	const [{ items }] = usePlansItems();
	const testId = testIdConcat(testIdPrefix, 'admin-footer');

	if (!items || !items.links) {
		return null;
	}

	const {
		links: {
			settings: { id, isVisible },
		},
	} = items;

	if (!isVisible) return null;

	return (
		<Section hasSeparator testId={testId}>
			<MenuItem
				analytics={{
					actionSubjectId: 'planSettingsNavigationMenuItem',
					menuItemId: id,
					menuItemType: 'action',
				}}
				href="/jira/plans/settings/permissions"
				key={id}
				testId={testIdConcat(testId, `item-${id}`)}
			>
				{formatMessage(messages.settings)}
			</MenuItem>
		</Section>
	);
};
