import React, { useCallback } from 'react';
import { AppSwitcher as AppSwitcherIntegrationDI } from '@atlassian/jira-atlassian-navigation-switcher-integration/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useTopMenus as useTopMenusDI } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { NAVIGATION_ITEM_ID } from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { useNavigationItemAnalytics as useNavigationItemAnalyticsDI } from '../../controllers/navigation-item-analytics/main.tsx';
import type { AppSwitcherProps } from './types';

const AppSwitcher = ({
	AppSwitcherIntegration = AppSwitcherIntegrationDI,
	useTopMenus = useTopMenusDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
}: AppSwitcherProps) => {
	const [isOpen, { on: openMenu, off: closeMenu }] = useTopMenus(MENU_ID.APP_SWITCHER);
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.APP_SWITCHER,
	});

	const onOpen = useCallback(() => {
		openMenu();
		triggerAnalytics();
	}, [openMenu, triggerAnalytics]);

	return <AppSwitcherIntegration isOpen={isOpen} onOpen={onOpen} onClose={closeMenu} />;
};

const AppSwitcherWithErrorBoundary = (props: AppSwitcherProps) => (
	<TopLevelErrorBoundary id={MENU_ID.APP_SWITCHER}>
		<AppSwitcher {...props} />
	</TopLevelErrorBoundary>
);

export { AppSwitcherWithErrorBoundary as AppSwitcher };
