import React from 'react';
import NotificationDirectIcon from '@atlaskit/icon/glyph/notification-direct';
import { COMMAND_PALETTE_SECTIONS } from '@atlassian/jira-command-palette-common/src/common/constants.tsx';
import { COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY } from '@atlassian/jira-command-palette-common/src/constants.tsx';
import { useCommandKeywords } from '@atlassian/jira-command-palette-common/src/controllers/use-command-keywords/index.tsx';
import { COMMAND_PALETTE_REGISTRY_IDS } from '@atlassian/jira-command-palette-registry/src/common/constants/registry/index.tsx';
import { useRegisterCommands } from '@atlassian/jira-command-palette-registry/src/controllers/use-register-commands/index.tsx';
import {
	type Command,
	CommandActionType,
} from '@atlassian/jira-command-palette/src/common/types/commands/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { CHECK_NOTIFICATIONS_COMMAND_ID } from './constants';
import { messages } from './messages';

export const useRegisterNotificationsInCommandPalette = (onClick: () => void) => {
	const { formatMessage } = useIntl();
	const { getKeywords } = useCommandKeywords();

	const commands: Command[] = [
		{
			id: CHECK_NOTIFICATIONS_COMMAND_ID,
			name: formatMessage(messages.checkNotifications),
			primaryAction: {
				type: CommandActionType.PERFORM,
				perform: onClick,
			},
			keywords: getKeywords('checkNotificationsSynonyms'),
			section: COMMAND_PALETTE_SECTIONS.quickActions,
			priority: COMMAND_PALETTE_QUICK_ACTIONS_PRIORITY.checkNotifications,
			components: {
				LeftIcon: () => (
					<NotificationDirectIcon label={formatMessage(messages.checkNotifications)} />
				),
			},
			analytics: {
				action: 'checkNotifications',
			},
		},
	];

	useRegisterCommands(COMMAND_PALETTE_REGISTRY_IDS.CHECK_NOTIFICATIONS, commands);
};
