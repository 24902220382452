import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context';
import {
	LICENSE_STATE_ACTIVE,
	type ApplicationLicenseStates,
} from '@atlassian/jira-shared-types/src/license-state';
import { useLicenseStates } from '@atlassian/jira-tenant-context-controller/src/components/license-states';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products';

const productToKey = (product: string): keyof ApplicationLicenseStates | null => {
	switch (product) {
		case LICENSED_PRODUCTS.JIRA_SOFTWARE:
			return 'software';
		case LICENSED_PRODUCTS.JIRA_SERVICEDESK:
			return 'serviceDesk';
		case LICENSED_PRODUCTS.JIRA_CORE:
			return 'core';
		case LICENSED_PRODUCTS.JIRA_PRODUCT_DISCOVERY:
			return 'productDiscovery';
		default:
			return null;
	}
};

/**
 * This hook checks if this site has only 1 Jira product and is Jira "Software".
 * The product need to be licensed and active.
 * This can be used in conjunction with CORE projects to determine if Jira Work Management is implicitly licensed.
 */
export const useSiteHasJiraOnly = (): boolean => {
	const licensedProducts = useLicensedProducts();
	const licenseStates = useLicenseStates();

	const activeJiraProductsCount = Object.entries(licensedProducts).filter(
		([productName, licensed]) => {
			if (productName.toLowerCase().startsWith('jira')) {
				const productKey = productToKey(productName);
				return licensed && productKey && licenseStates[productKey] === LICENSE_STATE_ACTIVE;
			}
			return false;
		},
	).length;

	return (
		activeJiraProductsCount === 1 &&
		!!licensedProducts[LICENSED_PRODUCTS.JIRA_SOFTWARE] &&
		licenseStates.software === LICENSE_STATE_ACTIVE
	);
};
