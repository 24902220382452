/**
 * @generated SignedSource<<70f6d71040252741bf3c076f2afde8cf>>
 * @relayHash 37e8ba7434b976190a64f1deb0262db4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e0bf19b8bde0f657198dc98794f5826367d59a6251a3875392c30f8d6c4d3cb9

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiWrapperEditionAwarenessDropdownWrapperQuery } from './uiWrapperEditionAwarenessDropdownWrapperQuery.graphql';

const node: PreloadableConcreteRequest<uiWrapperEditionAwarenessDropdownWrapperQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e0bf19b8bde0f657198dc98794f5826367d59a6251a3875392c30f8d6c4d3cb9",
    "metadata": {},
    "name": "uiWrapperEditionAwarenessDropdownWrapperQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
