import { type MouseEvent, type KeyboardEvent, useEffect, useRef } from 'react';
import { createHash } from 'rusha';
import { isMacOs } from '@atlassian/jira-common-components-keyboard-shortcuts-dialog/src/utils';
import { SHORTCUT_ELEMENT_TYPES } from './constants';
import type { ActiveRegistry, CommandPaletteRegistry, ShortcutSpecialChar, State } from './types';
import type { AnalyticsAttributes } from './types/analytics/index.tsx';
import {
	type CommandAction,
	type CommandId,
	type ListedCommand,
	type Command,
	type ChildResult,
	type CommandSections,
	type CommandInternal,
	COMMAND_WITH_CHILDREN,
} from './types/commands/index.tsx';

/** <registry> */

export const getRegistryData = (
	registry: CommandPaletteRegistry,
	activeRegistry: ActiveRegistry,
	commandId?: CommandId,
) => {
	if (!commandId) {
		return undefined;
	}
	const activeCommandRegistryData = activeRegistry?.commandRegistry?.[commandId];
	const registryData = activeCommandRegistryData?.registryId
		? registry[activeCommandRegistryData?.registryId]
		: undefined;
	return registryData;
};

export const getExpandedCommandRegistryAnalytics = (
	registry: CommandPaletteRegistry,
	activeRegistry: ActiveRegistry,
	commandId?: CommandId,
) => getRegistryData(registry, activeRegistry, commandId);

export const getParentCommandRegistryAnalytics = (
	registry: CommandPaletteRegistry,
	activeRegistry: ActiveRegistry,
	parentCommands?: Command[],
) => {
	const parentCommandIds = parentCommands?.map((command) => command.id);
	while (parentCommandIds?.length) {
		const nextParentCommandId = parentCommandIds?.pop();
		const registryData = getRegistryData(registry, activeRegistry, nextParentCommandId);
		if (registryData) {
			return registryData;
		}
	}
	return undefined;
};

/** </registry> */

/**
 * List of special words that are replaced when used
 * in the shortcuts field
 *
 * e.g some words are not replaced, but are in the list
 * because they are ignored when searching for shortcuts
 */
export const getShortcutSpecialCharacters = (): ShortcutSpecialChar[] => [
	{ char: ' ', replacement: SHORTCUT_ELEMENT_TYPES.THEN },
	{ char: '+', replacement: SHORTCUT_ELEMENT_TYPES.PLUS },
	{ char: '|', replacement: SHORTCUT_ELEMENT_TYPES.OR },
];

export const removeShortcutSpecialCharacters = (shortcut: string): string => {
	const specialChars = getShortcutSpecialCharacters();
	let strippedShortcut = shortcut;
	specialChars.forEach(({ char }) => {
		strippedShortcut = strippedShortcut.split(char).join('');
	});

	return strippedShortcut.toLowerCase();
};

/**
 * Sections is an array of section objects to avoid losing history
 * if two sections with same id are added. This function is to merge
 * all the array instances into one object keeping the latest object only
 */
export const mergeSections = (sections: CommandSections[]) =>
	sections.reduce<CommandSections>((acc, curr) => {
		Object.entries(curr).forEach(([key, entry]) => {
			acc[key] = entry;
		});

		return acc;
	}, {});

/**
 *
 * @param childResults ChildResult[]
 * @returns merges children and sections but removes fallback and renderAfterCommands
 */
export const mergeChildResults = (childResults: ChildResult[]): ChildResult => {
	const commands: Command[] = [];
	const sections: CommandSections[] = [];

	childResults.forEach((childResult) => {
		commands.push(...childResult.commands);
		if (childResult.sections) {
			sections.push(childResult.sections);
		}
	});

	return {
		commands,
		sections: mergeSections(sections),
	};
};

/**
 * Remove common words from text
 */
export const cleanUpText = (text: string, commonWordsToBeRemoved: string) => {
	const commonWords = commonWordsToBeRemoved
		.toLowerCase()
		.replace(/\s{2,}/g, ' ')
		.trim()
		.split(' ');

	const regexString = commonWords.map((word) => `\\b${word}\\b`).join('|');
	const regex = new RegExp(regexString, 'g');

	return text
		.replace(/[.,]/g, '') // remove dot and comma
		.replace(regex, '') // remove common words
		.replace(/\s{2,}/g, ' ') // remove double spaces
		.trim();
};

export const getAnalyticsAttributesFromState = (
	state: State,
	command: CommandInternal,
	method = 'undefined',
	wasSecondaryTriggered: boolean,
	attributes?: AnalyticsAttributes,
) => {
	const analyticsAttributes: AnalyticsAttributes = {
		commandPaletteId: command.id,
		commandPaletteSessionId: state.commandPaletteSessionId,
		searchTaken: !!state.search,
		method,
		cpMenu: 'rootMenu',
		action: command.id,
		actionCategory: 'none',
		wasSecondaryTriggered,
		...(getParentCommandRegistryAnalytics(
			state.registry,
			state.activeRegistry,
			command.parentCommands,
		) || {}),
		...(getRegistryData(state.registry, state.activeRegistry, command.id) || {}),
		...(getExpandedCommandRegistryAnalytics(
			state.registry,
			state.activeRegistry,
			state.expandedCommand?.id,
		) || {}),
		...attributes,
		...(!!command.analytics && command.analytics),
	};

	if (state.expandedCommand?.analytics) {
		analyticsAttributes.cpMenu = state.expandedCommand.analytics.action;
	} else if (state.expandedCommand?.id) {
		analyticsAttributes.cpMenu = state.expandedCommand.id;
	}

	const sections =
		state.expandedCommand && state.childResult?.sections
			? mergeSections([...state.sections, state.childResult?.sections])
			: mergeSections(state.sections);

	const rootCommand = state.parentCommand?.[0] || state.expandedCommand || command;
	const rootSection = rootCommand.section ? sections?.[rootCommand.section] : undefined;
	analyticsAttributes.actionCategory =
		rootSection?.actionCategory ||
		rootCommand?.section ||
		rootCommand?.analytics?.actionCategory ||
		// Fallback to the original command section
		(command?.section
			? sections?.[command?.section]?.actionCategory || command?.section
			: undefined) ||
		analyticsAttributes.actionCategory;

	return analyticsAttributes;
};

export const isSecondaryTrigger = (event: MouseEvent | KeyboardEvent): boolean =>
	isMacOs() ? event.metaKey : event.ctrlKey;

export const hasChildren = (command: CommandInternal | undefined): boolean => {
	if (command?.wasSecondaryTriggered) {
		if (command?.secondaryAction?.type) {
			const hasSecondaryChildren = COMMAND_WITH_CHILDREN.some(
				(commandType) => commandType === command?.secondaryAction?.type,
			);
			return hasSecondaryChildren;
		}
		// secondary action not defined - fallback to primary
		return COMMAND_WITH_CHILDREN.some(
			(commandType) => commandType === command?.primaryAction?.type,
		);
	}

	return COMMAND_WITH_CHILDREN.some((commandType) => commandType === command?.primaryAction?.type);
};

export const getAnalyticsAttributesFromResult = (result: ListedCommand) => {
	if (result) {
		return {
			globalIndex: result.index,
			sectionIndex: result.sectionIndex,
			indexWithinSection: result.indexWithinSection,
			sectionId: result.sectionId,
		};
	}
	return {};
};

export const getQueryHash = (str: string): string => createHash().update(str).digest('hex');

export const getWordCount = (str: string) => (str.length > 0 ? str.split(/\s+/).length : 0);

export const getCommandAction = (
	command?: CommandInternal,
	wasSecondaryTriggered?: boolean,
): CommandAction | null => {
	if (!command) return null;

	const { secondaryAction, primaryAction } = command;

	if (!primaryAction) return null;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const commandAction = (
		wasSecondaryTriggered && secondaryAction ? secondaryAction : primaryAction
	) as CommandAction;

	return {
		...commandAction,
	};
};

/**
 *  Verify if the command has any action defined
 */
export const hasAction = (command: Command) =>
	Boolean(command.primaryAction || command.secondaryAction);

export const useSetTimeoutAutoClear = () => {
	const timeoutRef = useRef<NodeJS.Timeout | undefined>();

	useEffect(
		() => () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		},
		[],
	);

	const setTimeoutAutoClear = (...args: Parameters<typeof setTimeout>) => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
		timeoutRef.current = setTimeout(...args);
	};

	return { setTimeoutAutoClear };
};
