import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { isSupportedProjectType, isTrialEndLockScreenEnabledForAnyProjectType } from '../utils';

export const LockScreenStore = createStore({
	initialState: {
		isLocked: false,
	},
	actions: {
		lockScreen:
			() =>
			({ setState }) =>
				setState({ isLocked: true }),
		unlockScreen:
			() =>
			({ setState }) =>
				setState({ isLocked: false }),
	},
	name: 'LockScreenExperiment',
});

export const useLockScreenController = createHook(LockScreenStore);

export const useIsScreenLocked = () => {
	const [lockScreenState] = useLockScreenController();
	const { loading: loadingProject, data: project } = useProject();
	const appEditions = useAppEditions();

	return (
		isTrialEndLockScreenEnabledForAnyProjectType(appEditions) &&
		!loadingProject &&
		isSupportedProjectType(project?.type) &&
		lockScreenState.isLocked
	);
};
