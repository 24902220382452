import React, { memo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import type { AtlaskitAtlassianNavigationNav4Props } from '@atlassian/jira-atlassian-navigation-nav4/src/ui/atlaskit-atlassian-navigation-nav4/types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';

const LazyAtlaskitAtlassianNavigationNav4 = lazyForPaint(() =>
	import(/* webpackChunkName: "async-jira-atlassian-navigation-nav4" */ './index').then(
		({ AtlaskitAtlassianNavigationNav4 }) => AtlaskitAtlassianNavigationNav4,
	),
);

const AsyncAtlaskitAtlassianNavigationNav4 = memo((props: AtlaskitAtlassianNavigationNav4Props) => (
	<JSErrorBoundary
		id="asyncAtlaskitAtlassianNavigationNav4"
		packageName="jiraAtlassianNavigationNav4"
		teamName="jira-cosmos"
	>
		<Placeholder name="lazyAtlaskitAtlassianNavigationNav4">
			<LazyAtlaskitAtlassianNavigationNav4 {...props} />
		</Placeholder>
	</JSErrorBoundary>
));

AsyncAtlaskitAtlassianNavigationNav4.displayName = 'AsyncAtlaskitAtlassianNavigationNav4';

export default AsyncAtlaskitAtlassianNavigationNav4;
