import React, { useCallback } from 'react';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { usePlansItems } from '@atlassian/jira-plans-item-store';
import { useCreateNewPlanWizardDrawer } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/controllers';
import { useCreateSamplePlan } from '@atlassian/jira-portfolio-3-create-sample-plan/src/controllers';
import { MenuItem } from './menu-item';
import messages from './messages';
import type { ActionFooterProps } from './types';
import { useIsPlanListEmpty } from './utils';

export const ActionFooter = ({ testIdPrefix }: ActionFooterProps) => {
	const { formatMessage } = useIntl();
	const [{ items }] = usePlansItems();
	const isPlanListEmpty = useIsPlanListEmpty();
	const testId = testIdConcat(testIdPrefix, 'action-footer');
	const [, { openDrawer: openCreateNewPlanWizardDrawer }] = useCreateNewPlanWizardDrawer();
	const [, { open: openCreateSamplePlanModal }] = useCreateSamplePlan();

	const onClick = useCallback(() => {
		openCreateNewPlanWizardDrawer();
	}, [openCreateNewPlanWizardDrawer]);

	if (!items || !items.links) {
		return null;
	}

	const { links } = items;
	const { manageTeams, managePlans, createPlan, createSamplePlan } = links;

	// Delete manageTeams.isVisible and the corresponding menuItem when removing the FF isMigrateArjTeamsToPlatformTeamsEnabled

	const isAnyItemVisible = manageTeams.isVisible || managePlans.isVisible || createPlan.isVisible;

	if (!isAnyItemVisible) return null;

	return (
		<span data-testId={testId}>
			<MenuItem
				{...links.manageTeams}
				title={formatMessage(messages.manageTeams)}
				testIdPrefix={testIdPrefix}
			/>
			<MenuItem
				{...links.managePlans}
				title={formatMessage(messages.managePlans)}
				testIdPrefix={testIdPrefix}
			/>
			<MenuItem
				id={links.createPlan.id}
				isVisible={links.createPlan.isVisible && !isPlanListEmpty}
				title={formatMessage(messages.createPlan)}
				testIdPrefix={testIdPrefix}
				onClick={onClick}
			/>
			{createSamplePlan && (
				<MenuItem
					id={createSamplePlan.id}
					isVisible={createSamplePlan.isVisible && !isPlanListEmpty}
					title={formatMessage(messages.createDemoPlan)}
					testIdPrefix={testIdPrefix}
					onClick={openCreateSamplePlanModal}
				/>
			)}
		</span>
	);
};
