import React, { useEffect } from 'react';
import { Section } from '@atlaskit/menu';
import { useAddon } from '@atlassian/jira-addon-store';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { useForgeGlobalItems } from '@atlassian/jira-forge-global-items-store';
import { getUsersApps } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { EXPERIENCE_NAVIGATION_TOP_MENU } from '../../../../common/constants';
import { Items } from './items/main.tsx';
import type { ContentProps } from './types';

export const Content = ({ testIdPrefix }: ContentProps) => {
	const [{ items, fetchError, promise, hasSuccessOnce, isFetching }] = useAddon();
	const [{ items: forgeItems }] = useForgeGlobalItems();

	const onSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
	});

	if (fetchError && !hasSuccessOnce) {
		throw fetchError;
	}

	if (isFetching) {
		throw promise;
	}

	useEffect(() => {
		if (hasSuccessOnce) {
			onSuccess();
		}
	}, [hasSuccessOnce, onSuccess]);

	const forgeGlobalPageItemsCondition = () => forgeItems && forgeItems.length > 0;

	return getUsersApps(items).length > 0 || forgeGlobalPageItemsCondition() ? (
		<Section testId={testIdPrefix}>
			<Items testIdPrefix={testIdPrefix} />
		</Section>
	) : null;
};
