import React, {
	type ComponentType,
	type ReactNode,
	type RefObject,
	useCallback,
	useContext,
} from 'react';
import { type PreloadedEntryPoint, loadEntryPoint } from 'react-relay';
import { SkeletonItem } from '@atlaskit/menu';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, {
	ModalBody,
	ModalHeader,
	ModalFooter,
	ModalTitle,
	type ModalDialogProps,
} from '@atlaskit/modal-dialog';
import { Stack } from '@atlaskit/primitives';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import type {
	AnyEntryPoint,
	ComponentOfEntryPoint,
	ParamsOfEntryPoint,
	RuntimePropsOfEntryPoint,
} from '@atlassian/jira-entry-point/src/common/types.tsx';
import {
	InternalEntryPointContainer,
	type InternalEntryPointContainerProps,
} from '@atlassian/jira-internal-entry-point-container';
import { ModalContext } from '@atlassian/jira-modal-context';
import { useJiraRelayEnvironmentProvider } from '@atlassian/jira-relay-environment-provider';
import usePressTracing from '@atlassian/react-ufo/use-press-tracing';
import { usePressablePreloadRef } from './usePressablePreloadRef';

export type ModalEntryPoint = AnyEntryPoint;

export type ModalEntryPointPressableTriggerProps<TEntryPoint extends ModalEntryPoint> = {
	children: ({ ref }: { ref: RefObject<HTMLElement> }) => ReactNode;
	entryPoint: TEntryPoint;
	entryPointParams?: keyof ParamsOfEntryPoint<TEntryPoint> extends never
		? undefined
		: ParamsOfEntryPoint<TEntryPoint>;
	entryPointProps?: Omit<RuntimePropsOfEntryPoint<TEntryPoint>, 'onClose'> & {
		onClose?: () => void;
	};
	Fallback?: ComponentType<{ onClose?: () => void }>;
	interactionName?: string;
	title?: ReactNode;
	modalProps?: Omit<ModalDialogProps, 'onClose'>;
	useInternalModal?: boolean;
} & (keyof ParamsOfEntryPoint<TEntryPoint> extends never
	? {}
	: {
			entryPointParams: unknown;
		}) &
	Partial<Pick<InternalEntryPointContainerProps<TEntryPoint>, 'errorAttributes'>>;

const emptyObject = {};

export function ModalEntryPointPressableTrigger<TEntryPoint extends ModalEntryPoint>({
	children,
	entryPoint,
	entryPointParams,
	entryPointProps,
	errorAttributes,
	Fallback,
	interactionName,
	title,
	modalProps,
	useInternalModal = false,
}: ModalEntryPointPressableTriggerProps<TEntryPoint>) {
	const { openModal } = useContext(ModalContext);
	const environmentProvider = useJiraRelayEnvironmentProvider();
	// eslint-disable-next-line jira/ufo/valid-labels
	const pressTracing = usePressTracing(interactionName ?? '');

	const load = useCallback(
		() =>
			loadEntryPoint(
				environmentProvider,
				entryPoint,
				// @ts-expect-error TS cannot infer that emptyObject is the correct params
				// type for TEntryPoint even when performing an explicit undefined check
				entryPointParams ?? emptyObject,
			),
		[entryPoint, entryPointParams, environmentProvider],
	);

	const onBeforeLoad = useCallback(() => {
		if (interactionName) {
			pressTracing();
		}
	}, [interactionName, pressTracing]);

	const onLoad = useCallback(
		({
			reference: entryPointReference,
			dispose,
		}: {
			reference: PreloadedEntryPoint<ComponentOfEntryPoint<TEntryPoint>>;
			dispose: () => void;
		}) => {
			openModal(({ onClose: closeModal }) => {
				const onClose = () => {
					entryPointProps?.onClose?.();
					closeModal();
					dispose();
				};
				const defaultFallbackInner = (
					<>
						<ModalHeader>
							{title ? (
								<ModalTitle>{title}</ModalTitle>
							) : (
								<Stack grow="fill">
									<SkeletonItem />
								</Stack>
							)}
						</ModalHeader>
						<ModalBody>
							<SkeletonItem />
							<SkeletonItem />
							<SkeletonItem />
						</ModalBody>
						<ModalFooter />
					</>
				);

				const defaultFallback = useInternalModal ? (
					defaultFallbackInner
				) : (
					<Modal onClose={closeModal} width={modalProps?.width} height={modalProps?.height}>
						{defaultFallbackInner}
					</Modal>
				);
				const container = (
					<InternalEntryPointContainer<typeof entryPoint>
						entryPointReference={entryPointReference}
						errorAttributes={errorAttributes}
						errorFallback="flag"
						fallback={Fallback ? <Fallback onClose={closeModal} /> : defaultFallback}
						// @ts-expect-error Need to fix the types on this
						id={entryPoint.root.getModuleName?.() ?? 'unknown'}
						placeholderName="modal-entry-point-pressable-trigger-container"
						// @ts-expect-error Some type mismatch, should fix it one day
						runtimeProps={{
							...(entryPointProps ?? emptyObject),
							onClose,
						}}
					/>
				);
				return useInternalModal ? (
					<ShortcutScope>
						<Modal {...modalProps} onClose={onClose}>
							{container}
						</Modal>
					</ShortcutScope>
				) : (
					container
				);
			});
		},
		[
			entryPoint,
			entryPointProps,
			errorAttributes,
			Fallback,
			openModal,
			title,
			modalProps,
			useInternalModal,
		],
	);

	const triggerRef = usePressablePreloadRef({
		load,
		onBeforeLoad,
		onLoad,
	});

	return children({ ref: triggerRef });
}
