import React from 'react';
import { Create as AkCreate, type CreateProps } from '@atlaskit/atlassian-navigation';
import { SkeletonCreateButton } from '@atlaskit/atlassian-navigation/skeleton';
import Button from '@atlaskit/button/new';
import AddIcon from '@atlaskit/icon/core/add';
import { GLOBAL_NAV_CREATE_BUTTON_SPOTLIGHT_ID } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/constants.tsx';
import { SpotlightTarget } from '@atlassian/jira-navigation-apps-sidebar-common/src/common/ui/spotlight-target/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { CreateButton as Nav4CreateButton } from '@atlassian/navigation-system';

// Should this live in the migration layer (@atlassian/jira-navigation-system) instead?
export const Create = (props: CreateProps) => {
	if (__SERVER__) {
		if (getWillShowNav4()) {
			return (
				<Button appearance="primary" iconBefore={AddIcon} testId={props.testId}>
					{props.text}
				</Button>
			);
		}
		return <SkeletonCreateButton text={props.text} testId={props.testId} />;
	}

	if (getWillShowNav4()) {
		const { onClick, text } = props;

		// This id is required to ensure pollinator tests work between Nav3 and Nav4
		const TEST_ID = 'atlassian-navigation--create-button';
		return (
			<SpotlightTarget name={GLOBAL_NAV_CREATE_BUTTON_SPOTLIGHT_ID}>
				<Nav4CreateButton onClick={onClick} testId={TEST_ID}>
					{text}
				</Nav4CreateButton>
			</SpotlightTarget>
		);
	}

	return <AkCreate {...props} />;
};
