import type {
	AnalyticsEventPayload,
	CreateUIAnalyticsEvent,
	UIAnalyticsEvent,
} from '@atlaskit/analytics-next';
import {
	toProjectType,
	type CUSTOMER_SERVICE_PROJECT,
	CORE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types';
import { COHORT, NOT_ENROLLED, VARIATION } from '@atlassian/jira-common-util-get-experiment-group';
import { UNSAFE_noExposureExp, expVal } from '@atlassian/jira-feature-experiments';
import {
	fireUIAnalytics,
	fireTrackAnalytics,
	type AnalyticsAttributes,
} from '@atlassian/jira-product-analytics-bridge';
import {
	CORE,
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
	SOFTWARE,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import {
	STANDARD_EDITION,
	type ApplicationEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';

export type SupportedProjectType =
	| typeof SERVICE_DESK_PROJECT
	| typeof PRODUCT_DISCOVERY_PROJECT
	| typeof SOFTWARE_PROJECT
	| typeof CUSTOMER_SERVICE_PROJECT
	| typeof CORE_PROJECT;
export const SUPPORTED_PROJECT_TYPES: SupportedProjectType[] = [
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
];

export const fireButtonAnalytics = (
	createAnalyticsEvent: (payload: AnalyticsEventPayload) => UIAnalyticsEvent,
	actionSubjectId: string,
	attributes?: AnalyticsAttributes,
) => {
	fireUIAnalytics(
		createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		}),
		actionSubjectId,
		{
			...attributes,
		},
	);
};

export const isSupportedProjectType = (value?: string | null): value is SupportedProjectType => {
	const projectType = toProjectType(value);

	return !!projectType && SUPPORTED_PROJECT_TYPES.includes(projectType);
};

export const getJsmJpdTrialAwarenessV2Cohort = () => {
	const [config] = UNSAFE_noExposureExp('jsm_jpd_trial_modal_improvement');
	return config.get(COHORT, NOT_ENROLLED);
};

export const isInVariationForJsmJpdTrialAwarenessV2 = () =>
	getJsmJpdTrialAwarenessV2Cohort() === VARIATION;

export const isEnrolledInJsmJpdTrialAwarenessV2 = (): boolean =>
	getJsmJpdTrialAwarenessV2Cohort() !== NOT_ENROLLED;

export const isEligibleForJsmJpdTrialAwarenessV2 = (projectType: string): boolean =>
	isEnrolledInJsmJpdTrialAwarenessV2() &&
	(toProjectType(projectType) === SERVICE_DESK_PROJECT ||
		toProjectType(projectType) === PRODUCT_DISCOVERY_PROJECT);

export const getJswTrialAwarenessV2Cohort = () => {
	const [config] = UNSAFE_noExposureExp('jsw_trial_modal_improvement');
	return config.get(COHORT, NOT_ENROLLED);
};

export const isInVariationForJswTrialAwarenessV2 = () =>
	getJswTrialAwarenessV2Cohort() === VARIATION;

export const isEnrolledInJswTrialAwarenessV2 = (): boolean =>
	getJswTrialAwarenessV2Cohort() !== NOT_ENROLLED;

export const isEligibleForJswTrialAwarenessV2 = (
	projectType: string,
	edition: ApplicationEdition,
): boolean =>
	isEnrolledInJswTrialAwarenessV2() &&
	toProjectType(projectType) === SOFTWARE_PROJECT &&
	edition === STANDARD_EDITION;

export const fireJsmJpdAwarenessV2ExperimentExposure = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	attributes: AnalyticsAttributes,
) => {
	const cohort = expVal('jsm_jpd_trial_modal_improvement', 'cohort', 'not-enrolled');

	// send GAS event
	fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', {
		flagKey: 'jsm_jpd_trial_modal_improvement',
		value: cohort,
		growthInitiative: 'transmuters',
		...attributes,
	});
};

export const fireJswAwarenessV2ExperimentExposure = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	attributes: AnalyticsAttributes,
) => {
	const cohort = expVal('jsw_trial_modal_improvement', 'cohort', 'not-enrolled');

	// send GAS event
	fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', {
		flagKey: 'jsw_trial_modal_improvement',
		value: cohort,
		growthInitiative: 'transmuters',
		...attributes,
	});
};

export const projectTypeToApplicationKey = (projectType: ProjectType) => {
	switch (projectType) {
		case SOFTWARE_PROJECT:
			return SOFTWARE;
		case SERVICE_DESK_PROJECT:
			return SERVICE_DESK;
		case CORE_PROJECT:
			return CORE;
		case PRODUCT_DISCOVERY_PROJECT:
			return PRODUCT_DISCOVERY;
		default:
			return CORE;
	}
};

const JIRA_SERVICE_MANAGEMENT_APP_NAME = 'Jira Service Management';
const JIRA_PRODUCT_DISCOVERY_APP_NAME = 'Jira Product Discovery';

export const getApplicationName = (projectType: ProjectType): string => {
	switch (projectType) {
		case SOFTWARE_PROJECT:
			return 'Jira';
		case SERVICE_DESK_PROJECT:
			return JIRA_SERVICE_MANAGEMENT_APP_NAME;
		case PRODUCT_DISCOVERY_PROJECT:
			return JIRA_PRODUCT_DISCOVERY_APP_NAME;
		default:
			throw new Error(`Unknown project type: ${projectType}`);
	}
};

export const getProductReferrerKey = (projectType: ProjectType): string | null => {
	if (projectType === SOFTWARE_PROJECT) {
		return 'jsw';
	}
	if (projectType === SERVICE_DESK_PROJECT) {
		return 'jsm';
	}
	if (projectType === PRODUCT_DISCOVERY_PROJECT) {
		return 'jpd';
	}
	if (projectType === CORE_PROJECT) {
		return 'jwm';
	}
	return null;
};
