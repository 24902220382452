import React, { useCallback, useMemo } from 'react';
import { styled } from '@compiled/react';
import { AtlassianNavigation as AkAtlassianNavigation } from '@atlaskit/atlassian-navigation';
import { token } from '@atlaskit/tokens';
import AsyncAtlaskitAtlassianNavigationNav4 from '@atlassian/jira-atlassian-navigation-nav4/src/async';
import {
	NavTopPerformanceMark,
	NavTopPerformanceReport,
	useNavPerformanceMetrics,
} from '@atlassian/jira-atlassian-navigation-performance-metrics';
import { NAVIGATION_TOP_MARKS } from '@atlassian/jira-atlassian-navigation-performance-metrics/src/constants.tsx';
import { recommendations } from '@atlassian/jira-atlassian-navigation-recommendations/src/ui';
import { RecommendationLoader } from '@atlassian/jira-atlassian-navigation-recommendations/src/ui/recommendation-loader/index.tsx';
import AsyncCustomThemeAdminFlag from '@atlassian/jira-custom-theme-admin-flag/src/async';
import { FavoritesContainer } from '@atlassian/jira-favorites-store';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsScreenLocked } from '@atlassian/jira-lock-screen-experiment-common/src/controllers/index.tsx';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { EditionAwareness } from '@atlassian/jira-navigation-apps-sidebar-edition-awareness/src/ui/index.tsx';
import AsyncNav4RolloutAnalytics from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-analytics/src/async';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { RecentsContainer } from '@atlassian/jira-recents-store';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { NavigationSuccessTrackerForNav3 } from '../controllers/navigation-success-tracker/main.tsx';
import { useTheme } from '../controllers/theme/main.tsx';
import { Addons } from './addons';
import { AppSwitcher } from './app-switcher/main.tsx';
import { ConversationAssistant } from './conversation-assistant';
import { Create } from './create/main.tsx';
import { Dashboards } from './dashboards';
import { Filters } from './filters';
import { Help } from './help/main.tsx';
import { InitialDataLoader } from './initial-data-loader';
import { Insight, useShowAssets } from './insight';
import { Integrations } from './integrations';
import messages from './messages';
import { Notifications } from './notifications/main.tsx';
import { People } from './people';
import { OpenOverflowMenuForPlansSpotlightObserver, Plans } from './plans';
import { ProductHome } from './product-home/main.tsx';
import { Profile } from './profile/main.tsx';
import { Projects } from './projects';
import { Roadmaps, useShowRoadmaps } from './roadmaps';
import { NavigationRouterListener } from './router-listener';
import { Search } from './search';
import { Settings } from './settings';
import { SignIn } from './sign-in/main.tsx';
import { YourWorkDropdown } from './your-work-dropdown';

/*
    The DiscoverMore component is from experiment TBLZ-197, so rather than updating the Atlassian Navigation
    package we are extending the renderNotifications prop for now.
    If this experiment is productionised, we will move the code into the appropriate location.
*/
const RenderNotificationsWithConversationAssistant = () => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isAnonymous = fg('rovo_chat_hide_for_jira_anon_users') ? useIsAnonymous() : false;
	return (
		<>
			{!isAnonymous && <ConversationAssistant />}
			<Notifications />
		</>
	);
};

const SearchWithoutProps = () => <Search />;

const EditionAwarenessAndSearch = () => (
	<>
		<EditionAwarenessWrapper>
			<EditionAwareness />
		</EditionAwarenessWrapper>
		<SearchWithoutProps />
	</>
);

const isSPA = () => {
	if (__SERVER__) return false;
	if (typeof window !== 'undefined') return window.__SPA__;
	return false;
};

export const AtlassianNavigation = () => {
	const topNavSegment = useNavPerformanceMetrics();
	topNavSegment.mark(NAVIGATION_TOP_MARKS.renderStart);

	const theme = useTheme();
	const { formatMessage } = useIntl();
	const cloudId = useCloudId();
	const applicationPermissions = useApplicationPermissions();
	const isAnonymous = useIsAnonymous();
	const isSiteAdmin = useIsSiteAdmin();
	const isScreenLocked = useIsScreenLocked();
	const showAssets = useShowAssets();
	const showRoadmaps = useShowRoadmaps();

	const PrimaryMenuItemsNav3 = useMemo(
		() =>
			[
				<OpenOverflowMenuForPlansSpotlightObserver key="arj-open-overflow-menu-for-plans-spotlight-observer" />,
				!isAnonymous && <YourWorkDropdown key="yourWorkDropdown" />,
				<Projects key="projects" />,
				<Filters key="filters" />,
				<Dashboards key="dashboards" />,
				<People key="people" />,
				<Plans key="plans" />,
				showRoadmaps && <Roadmaps key="roadmaps" />,
				showAssets && <Insight key="insight" />,
				<Addons key="addons" />,
			].filter(Boolean),
		[isAnonymous, showAssets, showRoadmaps],
	);

	const renderNavigation = useCallback(
		() => (
			<>
				{getWillShowNav4() ? (
					<AsyncAtlaskitAtlassianNavigationNav4
						label={formatMessage(messages.topNav)}
						renderAppSwitcher={AppSwitcher}
						renderProductHome={ProductHome}
						renderSearch={SearchWithoutProps}
						renderCreate={Create}
						renderEditionAwareness={EditionAwareness}
						renderHelp={Help}
						renderNotifications={Notifications}
						renderConversationAssistant={ConversationAssistant}
						renderSettings={Settings}
						renderProfile={Profile}
						renderSignIn={SignIn}
						theme={theme}
					/>
				) : (
					<AkAtlassianNavigation
						primaryItems={PrimaryMenuItemsNav3}
						label={formatMessage(messages.topNav)}
						moreLabel={formatMessage(messages.more)}
						renderAppSwitcher={AppSwitcher}
						renderCreate={Create}
						renderHelp={Help}
						renderNotifications={RenderNotificationsWithConversationAssistant}
						renderProductHome={ProductHome}
						renderProfile={Profile}
						renderSearch={EditionAwarenessAndSearch}
						renderSettings={Settings}
						renderSignIn={SignIn}
						theme={theme}
						testId={testIdGenerate(null)}
					/>
				)}
				{!__SERVER__ && (
					<>
						{!isAnonymous && !fg('navigation-disable-prefetched-data-for-dropdowns') && (
							<>
								<InitialDataLoader />
								<RecommendationLoader recommendations={recommendations} />
							</>
						)}
						<Integrations />
					</>
				)}
			</>
		),
		[PrimaryMenuItemsNav3, formatMessage, isAnonymous, theme],
	);

	const route = useCurrentRoute();
	const routeName = route.name ?? route.group ?? 'UNKNOWN';
	const projectContext = useProjectContext();
	const customData = useMemo(() => {
		if (fg('blu-2183_navtopperformancereport_custom_data'))
			return {
				route: routeName,
				projectType: projectContext?.data?.projectType ?? 'UNKNOWN',
				isSPA: isSPA(),
			};

		return undefined;
	}, [routeName, projectContext]);

	if (isScreenLocked) return null;

	return (
		<>
			<NavigationSuccessTrackerForNav3>
				{isAnonymous ? (
					renderNavigation()
				) : (
					<RecentsContainer
						isGlobal
						cloudId={cloudId}
						applicationPermissions={applicationPermissions}
					>
						<FavoritesContainer isGlobal applicationPermissions={applicationPermissions}>
							{renderNavigation()}
						</FavoritesContainer>
					</RecentsContainer>
				)}
			</NavigationSuccessTrackerForNav3>
			<NavigationRouterListener />
			<NavTopPerformanceMark metricKey={NAVIGATION_TOP_MARKS.renderEnd} />
			<NavTopPerformanceReport customData={customData} />
			{isSiteAdmin && <AsyncCustomThemeAdminFlag />}
			{fg('jira_nav4') && <AsyncNav4RolloutAnalytics />}
		</>
	);
};

export default AtlassianNavigation;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EditionAwarenessWrapper = styled.div({
	marginRight: token('space.200', '16px'),

	// On screen widths < 680px, hide the Edition Awareness component
	'@media (max-width: 680px)': {
		display: 'none',
	},
});
