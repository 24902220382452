import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import AkPopup, { type TriggerProps, type ContentProps } from '@atlaskit/popup';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { useFromSpotlightTour } from '../../../controllers/from-spotlight-tour';
import { MenuTrigger as MenuTriggerDI } from '../menu/trigger/main.tsx';
import type { PopupProps } from './types';
import { stopPropagation } from './utils';

const OFFSET: [number, number] = [12, -11];

export const Popup = ({
	Menu,
	MenuTrigger = MenuTriggerDI,
	children,
	menuId,
	navigationItemId,
	onBeforeMenuOpen = () => undefined,
	label,
}: PopupProps) => {
	const [isMenuOpen, { off: closeMenu }] = useTopMenus(menuId);
	const [isEventFromSpotlightTour] = useFromSpotlightTour('spotlight');
	const { isVisible } = useOverflowStatus();
	const Trigger = useCallback(
		(props: Partial<TriggerProps>) => (
			<MenuTrigger
				{...props}
				onBeforeMenuOpen={onBeforeMenuOpen}
				menuId={menuId}
				navigationItemId={navigationItemId}
				aria-haspopup={false}
			>
				{children}
			</MenuTrigger>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[children, menuId, navigationItemId, onBeforeMenuOpen],
	);
	const testId = testIdGenerate('primary-actions', menuId, 'menu-popup');
	const Content = useCallback(
		(props: Partial<ContentProps>) => (
			<span onClick={stopPropagation} data-testid={testId} role="presentation">
				<Menu {...props} testIdPrefix={testId}>
					{children}
				</Menu>
			</span>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[children, testId],
	);

	if (__SERVER__) return <Trigger aria-expanded={false} aria-haspopup={false} />;

	return (
		<AkPopup
			shouldRenderToParent
			isOpen={isMenuOpen}
			onClose={isEventFromSpotlightTour ? noop : closeMenu}
			content={Content}
			offset={isVisible ? undefined : OFFSET}
			placement={isVisible ? 'bottom-start' : 'right-start'}
			trigger={Trigger}
			label={label}
			role="menu"
		/>
	);
};
