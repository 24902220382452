import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import type { AppRecommendations } from '../fetch-app-recommendations/types';

const store = createLocalStorageProvider('projects-menu-jpd-recommendation');

const APP_RECOMMENDATION_STATE = 'app-recommendation-state';

interface AppRecommmendationState {
	fetchRecommendationTimeStamp: number;
	appRecommendations: AppRecommendations;
}

export function setCachedAppRecommendationState(appRecommendationState: AppRecommmendationState) {
	store.set(APP_RECOMMENDATION_STATE, JSON.stringify(appRecommendationState));
}

export function getCachedAppRecommendationState(): AppRecommmendationState | null {
	const state = store.get(APP_RECOMMENDATION_STATE);

	return state ? JSON.parse(state) : null;
}

export function deleteCachedAppRecommendationState() {
	store.remove(APP_RECOMMENDATION_STATE);
}
