import React, { useCallback, useRef } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import onCreateClick from '@atlassian/jira-create-issue-legacy/src';
import { useIsInitialLoadLegacyIssueCreate } from '@atlassian/jira-create-issue-legacy/src/controllers/is-initial-load-create-issue-legacy';
import { KEYBOARD_SHORTCUT } from '@atlassian/jira-create-issue-legacy/src/types';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	HELP_PANEL_ID,
	HELP_PANEL_WIDTH,
} from '@atlassian/jira-help-panel-common-constant/src/index.tsx';
import { KeyboardShortcuts as LazyShortcuts } from '@atlassian/jira-keyboard-shortcuts';
import {
	useRightSidebarController,
	useActiveRightSidebarState,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { GENERIC_KEYBOARD_SHORTCUT } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import {
	gicOptInStatusResources,
	type GicOptInStatusResourceData,
} from '@atlassian/jira-router-resources-global-issue-create-switch';
import { useResource } from '@atlassian/react-resource-router';
import { PACKAGE_NAME } from '../../../common/constants';

export const KeyboardShortcuts = () => {
	const [, { on }] = useTopMenus(MENU_ID.SEARCH);
	const [, { off: closeAllMenus }] = useTopMenus();
	const { openRightSidebar, closeCurrentRightSidebar } = useRightSidebarController();
	const rightSidebarState = useActiveRightSidebarState();
	const [{ isInitialLoad }, actions] = useIsInitialLoadLegacyIssueCreate();
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	const isInitialLoadRef = useRef<boolean>(false);
	isInitialLoadRef.current = isInitialLoad;

	const openSearch = useCallback(
		() =>
			requestAnimationFrame(() => {
				on();
			}),
		[on],
	);

	let currentIssueCreateVisibility: GicOptInStatusResourceData['gicOptInStatus'] | null = null;

	if (ff('project.config.pce.modern.global-issue-create')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { data } = useResource(gicOptInStatusResources);
		currentIssueCreateVisibility = data && data?.gicOptInStatus;
	}

	const openIssueCreate = useCallback(() => {
		if (ff('project.config.pce.modern.global-issue-create')) {
			if (currentIssueCreateVisibility !== null) {
				if (currentIssueCreateVisibility.isEnabled) {
					openIssueCreateModal({
						triggerPointKey: GENERIC_KEYBOARD_SHORTCUT,
					});
				} else {
					onCreateClick({
						isInitialLoad: isInitialLoadRef.current,
						triggerSource: KEYBOARD_SHORTCUT,
					});
					if (isInitialLoadRef.current) {
						actions.clearInitialLoaded();
					}
				}
			} else {
				log.safeErrorWithoutCustomerData(
					'global-issue-create',
					'visibility data is still getting downloaded',
				);
			}
		} else {
			onCreateClick({
				isInitialLoad: isInitialLoadRef.current,
				triggerSource: KEYBOARD_SHORTCUT,
			});
			if (isInitialLoadRef.current) {
				actions.clearInitialLoaded();
			}
		}
	}, [actions, currentIssueCreateVisibility, openIssueCreateModal]);

	const toggleHelpPanel = useCallback(() => {
		if (ff('iph.enabled')) {
			closeAllMenus();
			if (!rightSidebarState?.isCollapsed && rightSidebarState?.panelId === HELP_PANEL_ID) {
				closeCurrentRightSidebar();
			} else {
				openRightSidebar(HELP_PANEL_ID, HELP_PANEL_WIDTH);
			}
		}
	}, [
		closeAllMenus,
		closeCurrentRightSidebar,
		openRightSidebar,
		rightSidebarState?.isCollapsed,
		rightSidebarState?.panelId,
	]);

	return (
		<JSErrorBoundary
			id="integrations.keyboard-shortcuts"
			packageName={PACKAGE_NAME}
			fallback="unmount"
		>
			<Placeholder name="lazy-shortcuts" fallback={null}>
				<LazyShortcuts
					showIssueCreate={openIssueCreate}
					showSearch={openSearch}
					toggleHelpPanel={toggleHelpPanel}
					shouldUseHelpShortcut={ff('iph.enabled')}
				/>
			</Placeholder>
		</JSErrorBoundary>
	);
};
