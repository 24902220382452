import { createStore, createHook } from '@atlassian/react-sweet-state';
import { setHasOpened } from './actions/set-has-opened/index.tsx';
import type { State } from './types';

const actions = { setHasOpened } as const;

type Actions = typeof actions;

const initialState = {
	hasOpened: false,
} as const;

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'jsw-onboarding-quickstart-has-opened',
});

export const useHasOpened = createHook(Store);
