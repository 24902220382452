import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useShouldShowImporter } from '../use-should-show-importer';

export const useIsImportersOnGlobalNavEnabled = () => {
	const shouldShowImporter = useShouldShowImporter();
	if (!fg('jira_import_global_nav_dropdown')) {
		return false;
	}

	return shouldShowImporter && expVal('jira_importer_global_nav_dropdown_exp', 'isEnabled', false);
};
