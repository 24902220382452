import React, { useCallback, useRef } from 'react';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import OnCreateClickDI from '@atlassian/jira-create-issue-legacy/src';
import { useIsInitialLoadLegacyIssueCreate as useIsInitialLoadLegacyIssueCreateDI } from '@atlassian/jira-create-issue-legacy/src/controllers/is-initial-load-create-issue-legacy';
import { GLOBAL_CREATE_BUTTON } from '@atlassian/jira-create-issue-legacy/src/types';
import { ff } from '@atlassian/jira-feature-flagging';
import { useIntl } from '@atlassian/jira-intl';
import { useInitializePersistentIssueCreateModalView } from '@atlassian/jira-issue-create/src/controllers/use-initialize-persistent-issue-create-modal-view/index.tsx';
import { testIdGenerate } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { GENERIC_GLOBAL_CREATE_BUTTON } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/constants';
import { useTriggerIssueCreateModal } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/main.tsx';
import type { TriggerPointKeyType } from '@atlassian/jira-packages-controllers-use-trigger-issue-create-modal/src/types.tsx';
import {
	type GicOptInStatusResourceData,
	gicOptInStatusResources,
} from '@atlassian/jira-router-resources-global-issue-create-switch';
import { NavigationCreateIssueButtonWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/navigation-create-issue/async.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useResource } from '@atlassian/react-resource-router';
import { NAVIGATION_ITEM_ID } from '../../common/constants';
import { Create as AkCreateDI } from '../../common/ui/create-button';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { useNavigationItemAnalytics as useNavigationItemAnalyticsDI } from '../../controllers/navigation-item-analytics/main.tsx';
import { GlobalIssueCreateFromTopNav } from '../../experiences';
import messages from './messages';
import type { CreateProps } from './types';
import { shouldDisableGICFlagCreation } from './utils';

const noop = () => undefined;

const Create = ({
	AkCreate = AkCreateDI,
	NudgeTooltip = NavigationCreateIssueButtonWrapperNudgeAsync,
	onCreateClick = OnCreateClickDI,
	useNavigationItemAnalytics = useNavigationItemAnalyticsDI,
	// @ts-expect-error - TS2339 - Property 'useIsInitialLoadLegacyIssueCreate' does not exist on type 'CreateProps'.
	useIsInitialLoadLegacyIssueCreate = useIsInitialLoadLegacyIssueCreateDI,
}: CreateProps) => {
	const testId = testIdGenerate('create');
	const { formatMessage } = useIntl();
	const [, { openIssueCreateModal }] = useTriggerIssueCreateModal();
	useInitializePersistentIssueCreateModalView();

	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.CREATE,
	});
	const [{ isInitialLoad }, actions] = useIsInitialLoadLegacyIssueCreate();
	let currentIssueCreateVisibility: GicOptInStatusResourceData['gicOptInStatus'] | null = null;
	const gicOptInStateOverridden = useRef(false);
	const isAnonymous = useIsAnonymous();

	if (ff('project.config.pce.modern.global-issue-create')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { data, update } = useResource(gicOptInStatusResources);
		if (isAnonymous && !gicOptInStateOverridden.current) {
			update(() => ({
				gicOptInStatus: {
					isEnabled: true,
				},
			}));
			gicOptInStateOverridden.current = true;
		}

		currentIssueCreateVisibility = data && data?.gicOptInStatus;
	}

	const onClick = useCallback(
		// @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
		(e) => {
			if (ff('project.config.pce.modern.global-issue-create')) {
				if (currentIssueCreateVisibility !== null) {
					if (currentIssueCreateVisibility.isEnabled) {
						GlobalIssueCreateFromTopNav.abort({ force: true });
						GlobalIssueCreateFromTopNav.start();
						GlobalIssueCreateFromTopNav.addMetadata({
							inputDelay: Math.round(performance.now() - e.timeStamp),
						});

						const nextState: {
							disableCreationSuccessFlag: boolean;
							triggerPointKey: TriggerPointKeyType;
						} = {
							disableCreationSuccessFlag: shouldDisableGICFlagCreation(),
							triggerPointKey: GENERIC_GLOBAL_CREATE_BUTTON,
						};
						openIssueCreateModal(nextState);
					} else {
						onCreateClick({ isInitialLoad, triggerSource: GLOBAL_CREATE_BUTTON });
						if (isInitialLoad) {
							actions.clearInitialLoaded();
						}
					}
				} else {
					log.safeErrorWithoutCustomerData(
						'global-issue-create',
						'visibility data is still getting downloaded',
					);
				}
			} else {
				onCreateClick({ isInitialLoad, triggerSource: GLOBAL_CREATE_BUTTON });
				if (isInitialLoad) {
					actions.clearInitialLoaded();
				}
			}

			triggerAnalytics();
		},
		[
			actions,
			currentIssueCreateVisibility,
			isInitialLoad,
			onCreateClick,
			openIssueCreateModal,
			triggerAnalytics,
		],
	);

	if (!__SERVER__) {
		return (
			<NudgeTooltip>
				<AkCreate
					onClick={onClick}
					text={formatMessage(messages.create)}
					testId={testId}
					label={formatMessage(messages.createButtonAriaLabel)}
				/>
			</NudgeTooltip>
		);
	}

	return (
		<AkCreate
			onClick={noop}
			text={formatMessage(messages.create)}
			testId={testId}
			label={formatMessage(messages.createButtonAriaLabel)}
		/>
	);
};

const CreateWithErrorBoundary = (props: CreateProps) => (
	<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.CREATE}>
		<Create {...props} />
	</TopLevelErrorBoundary>
);

export { CreateWithErrorBoundary as Create };
