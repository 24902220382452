import React, { useEffect } from 'react';
import { useSiteHasJiraOnly } from '@atlassian/jira-billing/src/controllers/jsw-only-site';
import {
	PRODUCT_DISCOVERY_PROJECT,
	projectTypeToCanonicalId,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	toProjectType,
} from '@atlassian/jira-common-constants/src/project-types';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import {
	FREE_EDITION,
	getEditionForProject,
	PREMIUM_EDITION,
	STANDARD_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { EDITION_AWARENESS, PACKAGE_NAME } from '../common/constants';
import { useMeetsJswEATopNavRequisites } from '../common/utils';
import { useIsImplicitJWMLicense } from '../controllers/is-implicit-jwm-license';
import { EditionAwarenessButton } from './edition-awareness-next';
import { FreePill } from './free-pill';
import { TrialPill } from './trial-pill';
import type { TrialPillProps } from './trial-pill/types';

// Experiment specific code to be removed during cleanup
const isEnrolledInJPDFreeToStandard = () => {
	const [config] = UNSAFE_noExposureExp('jpd-free-to-upgrade-pill-au-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};

const ImplicitJWMTrialPill = (props: Omit<TrialPillProps, 'productKey' | 'projectType'>) => {
	const isImplicitJWMLicense = useIsImplicitJWMLicense();

	if (!isImplicitJWMLicense) {
		return null;
	}
	// productKey is set to "jira-software", because it only has an entitlement with JSW, not with JWM.
	// projectType is the real value ("business").
	return (
		<UFOSegment name="edition-awareness-implicit-jwm-premium-trial">
			<TrialPill
				{...props}
				productKey={projectTypeToCanonicalId(SOFTWARE_PROJECT)}
				projectType={CORE_PROJECT}
			/>
		</UFOSegment>
	);
};

const ProductAndEditionChecker = () => {
	const appEditions = useAppEditions();
	const { loading, data: project } = useProject();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const userMeetsJswTopNavRequisites = useMeetsJswEATopNavRequisites();
	const siteHasJiraOnly = useSiteHasJiraOnly();

	useEffect(() => {
		if (siteHasJiraOnly !== userMeetsJswTopNavRequisites) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'siteWithJiraOnlyCheck mismatch', {
				siteHasJiraOnly,
				userMeetsJswTopNavRequisites,
			});
		}
	}, [siteHasJiraOnly, userMeetsJswTopNavRequisites, createAnalyticsEvent]);

	const siteHasJswOnly = fg('jira-software-only-site-improved')
		? siteHasJiraOnly
		: userMeetsJswTopNavRequisites;

	// If the JSW EA top nav relocation is active, we want to default the project type to JSW
	// so that the pill can show even on product-agnostic pages (e.g. "Your work")
	const defaultProjectType = siteHasJswOnly ? SOFTWARE_PROJECT : undefined;
	const projectType = toProjectType(project?.type) || defaultProjectType;

	if (loading || !projectType) {
		return null;
	}

	const edition = getEditionForProject(projectType, appEditions);
	const productKey = projectTypeToCanonicalId(projectType);

	if (projectType === SOFTWARE_PROJECT || projectType === SERVICE_DESK_PROJECT) {
		switch (edition) {
			case FREE_EDITION:
				if (projectType === SERVICE_DESK_PROJECT) {
					return (
						<UFOSegment name="edition-awareness-jsm-free-to-standard">
							<Placeholder name="edition-awareness-button-placeholder" fallback={null}>
								<EditionAwarenessButton
									applicationEdition="FREE_EDITION"
									applicationKey="jira-servicedesk"
									projectType={projectType}
								/>
							</Placeholder>
						</UFOSegment>
					);
				}

				return (
					<UFOSegment name="edition-awareness-jsw-jsm-free">
						<FreePill projectType={projectType} siteHasJswOnly={siteHasJswOnly} />
					</UFOSegment>
				);
			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsw-jsm-standard-premium-trial">
						<TrialPill productKey={productKey} projectType={projectType} edition={edition} />
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === PRODUCT_DISCOVERY_PROJECT) {
		if (fg('jpd_disable_edition_awareness')) {
			return null;
		}
		switch (edition) {
			case FREE_EDITION:
				if (isEnrolledInJPDFreeToStandard()) {
					return (
						<UFOSegment name="edition-awareness-jpd-free-to-standard">
							<Placeholder name="edition-awareness-button-placeholder" fallback={null}>
								<EditionAwarenessButton
									applicationEdition="FREE_EDITION"
									applicationKey="jira-product-discovery"
									projectType={projectType}
								/>
							</Placeholder>
						</UFOSegment>
					);
				}

				return null;
			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jpd-standard-premium-trial">
						<TrialPill productKey={productKey} projectType={projectType} edition={edition} />
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === CORE_PROJECT) {
		// We are supporting only JWM implicit licensing when the parent license is JSW
		switch (edition) {
			case FREE_EDITION:
				if (siteHasJswOnly) {
					return (
						<UFOSegment name="edition-awareness-jsw-jsm-free">
							<FreePill projectType={CORE_PROJECT} siteHasJswOnly={siteHasJswOnly} />
						</UFOSegment>
					);
				}
				return null;
			case STANDARD_EDITION:
				if (siteHasJswOnly) {
					// productKey is set to "jira-software", because it only has an entitlement with JSW, not with JWM.
					// projectType is the real value ("business").
					return (
						<UFOSegment name="edition-awareness-implicit-jwm-standard-trial">
							<TrialPill edition={edition} productKey="jira-software" projectType={CORE_PROJECT} />
						</UFOSegment>
					);
				}
				return null;
			case PREMIUM_EDITION:
				// Core projects are partially supported - only for implicit JWM, on premium, on the top nav
				return <ImplicitJWMTrialPill edition={edition} />;
			default:
				return null;
		}
	}
	return null;
};

export const EditionAwareness = () => (
	<JSErrorBoundary
		id={EDITION_AWARENESS}
		packageName={PACKAGE_NAME}
		fallback="unmount"
		teamName="growth-tako"
	>
		<UFOSegment name="edition-awareness">
			<ProductAndEditionChecker />
		</UFOSegment>
	</JSErrorBoundary>
);
