import React from 'react';
import { lazy } from 'react-loosely-lazy';
import type ModalIssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app';
import { ModalBoundary } from '@atlassian/jira-modal/src/ui/modal-boundary/index.tsx';
import { useTriggerIssueViewModal } from '@atlassian/jira-open-issue-view-modal-controller';
import Placeholder from '@atlassian/jira-placeholder';
import { toIssueKey } from '@atlassian/jira-shared-types/src/general.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyIssueViewModal = lazy<typeof ModalIssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-modal-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app'
		),
);

export const IssueViewModal = () => {
	const [{ isModalOpen, issueKey, onClose, packageName, analyticsSource }] =
		useTriggerIssueViewModal();

	return isModalOpen ? (
		<ModalBoundary packageName={packageName} id="issue-modal">
			<Placeholder name="modal-issue-app" fallback={null}>
				<LazyIssueViewModal
					// @ts-expect-error - TS2322 - Type 'string' is not assignable to type 'AnalyticsSource'.
					analyticsSource={analyticsSource}
					issueKey={toIssueKey(issueKey)}
					onClose={onClose}
				/>
			</Placeholder>
		</ModalBoundary>
	) : null;
};
