import { useEffect, useRef, type DependencyList } from 'react';
import type { Command } from '../../common/types/commands/index.tsx';
import { useSubscribeCommandsWithRegistry } from '../use-subscribe-commands';

/**
 * @deprecated if using in jira. Prefer:
 * import { useRegisterCommands } from '@atlassian/jira-command-palette-registry';
 */
export function useRegisterCommandsWithRegistry<T extends string>(
	registrationId: T,
	commands: Command[],
	dependencies: DependencyList = [],
) {
	const subscribe = useSubscribeCommandsWithRegistry<T>();

	const commandCache = useRef(commands);

	useEffect(() => {
		commandCache.current = commands;
	}, [commands]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => subscribe(registrationId, commandCache.current), [...dependencies, subscribe]);
}
