import React, { useEffect } from 'react';
import { Text } from '@atlaskit/primitives';
import { useExperienceSuccess as useExperienceSuccessDI } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { useFavoriteFilters as useFavoriteFilterDI } from '@atlassian/jira-favorites-store';
import { useIntlV2 as useIntlDI } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { useRecentFilters as useRecentFilterDI } from '@atlassian/jira-recents-store';
import { useIsAnonymous as useIsAnonymousDI } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { EXPERIENCE_NAVIGATION_TOP_MENU } from '../../../../common/constants';
import { MenuInfo as MenuInfoDI } from '../../../../common/ui/menu/info/main.tsx';
import { useFilterNavItems as useFiltersNavItemsDI } from '../../../../controllers/items';
import defaultEmptyImg from './assets/filter-empty.svg';
import messages from './messages';
import type { EmptyProps } from './types';

const learnMoreHref =
	'https://confluence.atlassian.com/jirasoftwarecloud/searching-for-issues-764478280.html#Searchingforissues-SearchallofJira';

export const Empty = ({
	MenuInfo = MenuInfoDI,
	children,
	testIdPrefix,
	useExperienceSuccess = useExperienceSuccessDI,
	useFavorite = useFavoriteFilterDI,
	useIntl = useIntlDI,
	useIsAnonymous = useIsAnonymousDI,
	useItems = useFiltersNavItemsDI,
	useRecent = useRecentFilterDI,
}: EmptyProps) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const onSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
	});
	const text = (
		<>
			<div>
				<Text as="p">{formatMessage(messages.emptyText)}</Text>
			</div>
			<div>
				<a href={learnMoreHref} target="_blank" rel="noopener noreferrer">
					{formatMessage(messages.learnMore)}
				</a>
			</div>
		</>
	);

	const [
		{
			isFetching: isFetchingFavorite,
			hasSuccessOnce: hasSuccessOnceFavorite,
			fetchError: fetchErrorFavorite,
		},
	] = useFavorite();
	const [
		{
			isFetching: isFetchingRecent,
			hasSuccessOnce: hasSuccessOnceRecent,
			fetchError: fetchErrorRecent,
		},
	] = useRecent();
	const { favoriteItems, recentItems } = useItems();

	const notEmpty =
		favoriteItems.length || //  should show fav items
		recentItems.length || // should show recent items
		(isFetchingFavorite && !hasSuccessOnceFavorite) || // should show fav skeleton
		(isFetchingRecent && !hasSuccessOnceRecent) || // should show recent skeleton
		fetchErrorFavorite || // should show error
		fetchErrorRecent; // should show error

	const shouldShowEmptyForAnonymous = isAnonymous;

	useEffect(() => {
		const isSuccess = hasSuccessOnceFavorite && hasSuccessOnceRecent;
		if (isSuccess) {
			onSuccess();
		}
	}, [hasSuccessOnceFavorite, hasSuccessOnceRecent, onSuccess]);

	const testId = testIdConcat(testIdPrefix, 'empty');

	return notEmpty && !shouldShowEmptyForAnonymous ? (
		<>{children}</>
	) : (
		<MenuInfo imgSrc={defaultEmptyImg} testId={testId} text={text} />
	);
};
