import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types';
import type {
	PREMIUM_EDITION,
	STANDARD_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';

export type SupportedApplicationEdition = typeof STANDARD_EDITION | typeof PREMIUM_EDITION;

export const FEATURE_INVITE_PEOPLE = 'invite-people';
export const FEATURE_AUTOMATIONS = 'automations';
export const FEATURE_USER_ACTIVITY_LOGS = 'user-activity-logs';
export const FEATURE_ATLASSIAN_INTELLIGENCE = 'atlassian-intelligence';
export const FEATURE_ISSUE_PERMISSIONS = 'issue-permissions';
export const FEATURE_CUSTOM_PROJECT_ROLES = 'custom-project-roles';
export const FEATURE_DATA_RESIDENCY = 'data-residency';
export const FEATURE_AUDIT_LOGS = 'audit-logs';
export const FEATURE_CUSTOM_BRANDING = 'custom-branding';

export type Feature =
	| typeof FEATURE_INVITE_PEOPLE
	| typeof FEATURE_AUTOMATIONS
	| typeof FEATURE_USER_ACTIVITY_LOGS
	| typeof FEATURE_ATLASSIAN_INTELLIGENCE
	| typeof FEATURE_ISSUE_PERMISSIONS
	| typeof FEATURE_CUSTOM_PROJECT_ROLES
	| typeof FEATURE_DATA_RESIDENCY
	| typeof FEATURE_AUDIT_LOGS
	| typeof FEATURE_CUSTOM_BRANDING;

export type OnFeatureClickHandler = (
	e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
	analyticsEvent: UIAnalyticsEvent,
) => void;

export type InnerOnFeatureClickHandler = (feature: Feature) => OnFeatureClickHandler;

export type ApplicationProps = {
	productKey: CanonicalId;
	applicationEdition: SupportedApplicationEdition;
	onFeatureClick: (
		e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
		analyticsEvent: UIAnalyticsEvent,
		featureId: Feature,
	) => void;
	trialEndTime?: number;
};
