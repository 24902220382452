import React, { type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import CustomThemeButton, {
	type ThemeProps,
	type ThemeTokens,
} from '@atlaskit/button/custom-theme-button';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import { GemIcon } from '@atlassian/jira-product-edition-upgrade/src/ui/gem-icon/index.tsx';
import SparkleIcon from '@atlassian/jira-product-edition-upgrade/src/ui/sparkle-icon/index.tsx';
import {
	EATopNavPillCss,
	EATopNavAnimatedButtonContainer,
	EATopNavPillTheme,
	IconWrapper,
} from '../../../../common/ui/styled/styled';
import messages from './messages';

type ButtonProps = {
	triggerRef: EntryPointButtonTrigger;
	onButtonClick: () => void;
	onHoverButton: () => void;
};

export const UpgradeButton = (props: ButtonProps) => {
	const { triggerRef, onButtonClick, onHoverButton } = props;
	const { formatMessage } = useIntl();

	const UpgradeIcon = expValEquals('ea_uses_gem_icon', 'cohort', 'variation')
		? GemIcon
		: SparkleIcon;

	return (
		<EATopNavAnimatedButtonContainer>
			<CustomThemeButton
				iconBefore={
					<IconWrapper>
						<UpgradeIcon label="" />
					</IconWrapper>
				}
				onClick={onButtonClick}
				theme={EATopNavPillTheme}
				onMouseEnter={onHoverButton}
				ref={triggerRef}
				interactionName="edition-awareness-users-remaining-free-pill"
				testId="navigation-apps-sidebar-edition-awareness.ui.free-pill.users-remaining-free-pill.buttons.upgrade"
			>
				<Box as="span" xcss={eaTopNavButtonTextStyles}>
					{formatMessage(messages.upgrade)}
				</Box>
			</CustomThemeButton>
		</EATopNavAnimatedButtonContainer>
	);
};

export const LimitedUsersLeftButton = (props: ButtonProps) => {
	const { triggerRef, onButtonClick, onHoverButton } = props;
	const { formatMessage } = useIntl();

	return (
		<TopNavWarningButtonContainer>
			<CustomThemeButton
				iconBefore={
					<TopNavDoritoIconWrapper>
						<WarningIcon label="" size="small" />
					</TopNavDoritoIconWrapper>
				}
				onClick={onButtonClick}
				theme={TopNavWarningButtonTheme}
				onMouseEnter={onHoverButton}
				ref={triggerRef}
				interactionName="edition-awareness-users-remaining-free-pill"
				testId="navigation-apps-sidebar-edition-awareness.ui.free-pill.users-remaining-free-pill.buttons.limited-users-left"
			>
				<Box as="span" xcss={topNavWarningButtonTextStyles}>
					{formatMessage(messages.limitedUsersLeft)}
				</Box>
			</CustomThemeButton>
		</TopNavWarningButtonContainer>
	);
};

export const NoUsersLeftButton = (props: ButtonProps) => {
	const { triggerRef, onButtonClick, onHoverButton } = props;
	const { formatMessage } = useIntl();

	return (
		<TopNavWarningButtonContainer>
			<CustomThemeButton
				iconBefore={
					<TopNavDoritoIconWrapper>
						<WarningIcon label="" size="small" />
					</TopNavDoritoIconWrapper>
				}
				onClick={onButtonClick}
				theme={TopNavWarningButtonTheme}
				onMouseEnter={onHoverButton}
				ref={triggerRef}
				interactionName="edition-awareness-users-remaining-free-pill"
				testId="navigation-apps-sidebar-edition-awareness.ui.free-pill.users-remaining-free-pill.buttons.no-users-left"
			>
				<Box as="span" xcss={topNavWarningButtonTextStyles}>
					{formatMessage(messages.noUsersLeft)}
				</Box>
			</CustomThemeButton>
		</TopNavWarningButtonContainer>
	);
};

const TopNavDoritoIconWrapper = (props: PropsWithChildren) => <>{props.children}</>;

const WarningButtonStyles = {
	color: token('color.icon.warning', colors.Y500),

	'&:hover': {
		cursor: 'pointer',
		background: `linear-gradient(79.51deg, ${token(
			'color.background.accent.orange.subtler.pressed',
			colors.Y300,
		)} 0%, ${token('color.icon.warning', colors.Y500)} 98.15%)`,
		color: token('color.text.inverse', '#FFFFFF'),

		svg: {
			// Fix the exclamation mark not being visible when the SVG icon's primary color is white
			fill: token('color.chart.orange.bold', colors.Y400),
		},
	},

	'&:active': {
		background: `linear-gradient(79.51deg,
                ${token('color.chart.orange.bold', colors.Y400)} 0%,
                ${token('color.chart.orange.bolder', colors.Y500)} 100%)`,
	},
};

const TopNavWarningButtonTheme = (
	currentTheme: (props: ThemeProps) => ThemeTokens,
	themeProps: ThemeProps,
) => {
	const theme = currentTheme(themeProps);

	return {
		...theme,
		buttonStyles: {
			...EATopNavPillCss,
			...WarningButtonStyles,
			transition: 'all 0.3s ease-in-out 0s, color 0s',
		},
	};
};

const topNavWarningButtonTextStyles = xcss({
	font: 'font.body',
});

const eaTopNavButtonTextStyles = xcss({
	font: 'font.body',
	color: 'color.text.accent.blue',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TopNavWarningButtonContainer = styled.div({
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	border: `1px solid ${token('color.background.accent.orange.subtler.pressed', colors.Y300)}`,
	height: '30px',
	display: 'inline-flex',
});
