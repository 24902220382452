import React, { useCallback, useEffect } from 'react';
import { styled } from '@compiled/react';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { Section, LinkItem, HeadingItem } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useAddon } from '@atlassian/jira-addon-store';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { getUsersApps } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import {
	useAnalyticsEvents,
	fireUIAnalytics,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { MenuList, MenuListItem } from '../../../../common/ui/menu/list';
import useFetchIntegrations from '../../../../services/fetch-integrations';
import { RECOMMENDATION_TYPE } from './constants';
import type { IntegrationLinkProps, DiscoverSectionProps } from './types';

const IntegrationLink = ({
	url,
	label,
	icon,
	actionSubjectId,
	onClick,
	recommendationType = RECOMMENDATION_TYPE.Personalised,
}: IntegrationLinkProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { off: closeMenu }] = useTopMenus();
	const { closeOverflowMenu } = useOverflowStatus();

	const onLinkClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'navigationMenuItem clicked', actionSubjectId);
		fireUIAnalytics(
			createAnalyticsEvent({}),
			'navigationMenuItem clicked',
			`${recommendationType}AppRecommendedItem`,
			{
				appName: actionSubjectId,
			},
		);

		if (onClick) {
			onClick();
		}
		closeMenu();
		closeOverflowMenu();
	}, [
		actionSubjectId,
		closeMenu,
		closeOverflowMenu,
		createAnalyticsEvent,
		onClick,
		recommendationType,
	]);

	return (
		<LinkItem
			iconBefore={<img src={icon} width="24" height="24" alt={`${actionSubjectId} logo`} />}
			href={url}
			target="_blank"
			onClick={onLinkClick}
		>
			{label}
		</LinkItem>
	);
};

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const suspensePromise = new Promise(() => {});

export const DiscoverSection = ({
	apps = [],
	isLoadingApps,
	title,
	description,
	recommendationType,
	setIsAppsMenuFinishedRendering,
	isFedrampEnvironment,
}: DiscoverSectionProps) => {
	const isSiteAdmin = useIsSiteAdmin();
	const { formatMessage } = useIntl();
	const [{ items }] = useAddon();

	const [{ fetchError: fetchIntegrationsError }] = useFetchIntegrations();

	useEffect(() => {
		if (!isLoadingApps) {
			setIsAppsMenuFinishedRendering(true);
		}
	}, [isLoadingApps, setIsAppsMenuFinishedRendering]);

	if (isFedrampEnvironment) {
		return null;
	}

	if (fetchIntegrationsError) {
		throw fetchIntegrationsError;
	}

	if (isLoadingApps) {
		throw suspensePromise;
	}

	return apps.length ? (
		<Section hasSeparator={getUsersApps(items).length > 0}>
			<HeadingItem>{formatMessage(title)}</HeadingItem>
			<TextWrapper>{formatMessage(description)}</TextWrapper>
			<MenuList>
				{apps.map((props) => {
					const { url } = props;
					return (
						<MenuListItem key={url} ariaLabel={formatMessage(title)}>
							<IntegrationLink {...props} recommendationType={recommendationType} />
						</MenuListItem>
					);
				})}
			</MenuList>
			<FireScreenAnalytics
				attributes={{
					availableProducts: apps.map(({ actionSubjectId }) => actionSubjectId),
					recommendationType,
					isSiteAdmin,
				}}
			/>
		</Section>
	) : null;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextWrapper = styled.small({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtlest', colors.N200),
	padding: `${token('space.050', '4px')} ${token('space.250', '20px')}`,
	margin: 0,
	display: 'block',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 35}px`,
});
