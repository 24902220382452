import { fg } from '@atlassian/jira-feature-gating';

const PREFIX = 'atlassian-navigation-';
const SEPARATOR = '--';

const isValid = (value?: string): boolean => value != null && value !== '';

export const testIdConcat = (id?: string, ...items: string[]): string | undefined =>
	fg('navigation-disable-test-ids')
		? undefined
		: [id, ...items].filter(Boolean).join(SEPARATOR).toLowerCase();

export const testIdGenerate = (
	first: string | null | undefined,
	...rest: string[]
): string | undefined =>
	fg('navigation-disable-test-ids')
		? undefined // @ts-expect-error - TS2345 - Argument of type 'string | null | undefined' is not assignable to parameter of type 'string | undefined'.
		: testIdConcat(PREFIX + (isValid(first) ? `-${first}` : ''), ...rest);
