import { useEffect } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useCreateProjectPermission } from '@atlassian/jira-nav-item-service/src/services/create-project-permisson';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useFirstActivationDateMs } from '@atlassian/jira-tenant-context-controller/src/components/first-activation-date-ms/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { RecommendationLoaderProps } from '../../common/types';
import { useProjectMenuRecommendations } from '../../controllers/project-menu-recommendations';

export const RecommendationLoader = ({ recommendations }: RecommendationLoaderProps) => {
	const [, { loadRecommendations }] = useProjectMenuRecommendations();
	const accountId = useAccountId();
	const locale = useLocale();
	const cloudId = useCloudId();
	const licensedProducts = useLicensedProducts();
	const firstActivationDateMs = useFirstActivationDateMs();
	const environment = useRelayEnvironment();
	const { software: edition } = useAppEditions();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [{ hasSuccessOnce, canCreateProject }] = useCreateProjectPermission();
	const { areProductSuggestionsEnabled } = useTenantContext();

	useEffect(() => {
		if (hasSuccessOnce && accountId) {
			loadRecommendations(
				recommendations,
				{
					locale,
					accountId,
					cloudId,
					licensedProducts,
					firstActivationDateMs,
					areProductSuggestionsEnabled,
					edition,
					environment,
					canCreateProject,
				},
				createAnalyticsEvent({}),
			);
		}
	}, [
		hasSuccessOnce,
		canCreateProject,
		accountId,
		loadRecommendations,
		createAnalyticsEvent,
		locale,
		cloudId,
		licensedProducts,
		firstActivationDateMs,
		areProductSuggestionsEnabled,
		edition,
		environment,
		recommendations,
	]);

	return null;
};
