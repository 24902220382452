import React, { useLayoutEffect, type FC } from 'react';
import { lazy, lazyAfterPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PERFORMANCE_MARK } from '@atlassian/jira-onboarding-checklist-next/src/common/constants.tsx';
import {
	openMenuItemLoadTime,
	qsButtonAutoLoadTime,
	qsChecklistAutoLoadTime,
} from '@atlassian/jira-onboarding-checklist-next/src/common/utils/performance-metrics.tsx';
import { addChunkLoadErrorAnalyticsAttribute } from '@atlassian/jira-onboarding-core/src/utils/chunk-error-logging';
import Placeholder from '@atlassian/jira-placeholder';
import UFOSegment from '@atlassian/jira-ufo-segment';
import type { Props, OpenMenuItem as OpenMenuItemType } from './open-menu-item';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/deprecations/no-rll-client-async-experiences
const LazyChecklist = lazyAfterPaint<FC<Record<any, any>>>(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-checklist-new" */ '@atlassian/jira-onboarding-checklist-next/src/ui/onboarding-checklist'
		).then(({ JswOnboardingChecklistNext }) => JswOnboardingChecklistNext),
	{ ssr: false },
);

const FallbackChecklist = () => {
	useLayoutEffect(() => {
		qsChecklistAutoLoadTime.start();
	}, []);

	return <></>;
};

export const JswOnboardingChecklist = () =>
	fg('jsw_onboarding_reactufo_qs') ? (
		<UFOSegment name="quickstart">
			<JSErrorBoundary
				id="asyncChecklistComponent"
				packageName="jiraSoftwareOnboardingQuickstart"
				teamName="jlove-makkuro"
				fallback="unmount"
				attributes={addChunkLoadErrorAnalyticsAttribute}
			>
				<Placeholder name="software-onboarding-checklist-new" fallback={<FallbackChecklist />}>
					<LazyChecklist />
				</Placeholder>
			</JSErrorBoundary>
		</UFOSegment>
	) : (
		<JSErrorBoundary
			id="asyncChecklistComponent"
			packageName="jiraSoftwareOnboardingQuickstart"
			teamName="jlove-makkuro"
			fallback="unmount"
			attributes={addChunkLoadErrorAnalyticsAttribute}
		>
			<Placeholder name="software-onboarding-checklist-new" fallback={<FallbackChecklist />}>
				<LazyChecklist />
			</Placeholder>
		</JSErrorBoundary>
	);

// eslint-disable-next-line @typescript-eslint/no-explicit-any, jira/deprecations/no-rll-client-async-experiences
const LazyOpenButton = lazyAfterPaint<FC<Record<any, any>>>(
	() =>
		import(
			/* webpackChunkName: "async-software-onboarding-checklist-button-new" */ './onboarding-button'
		).then(({ JswOnboardingButtonNext }) => JswOnboardingButtonNext),
	{ ssr: false },
);

const FallbackButton = () => {
	useLayoutEffect(() => {
		qsButtonAutoLoadTime.start();
	}, []);

	return <></>;
};

export const JswOnboardingButton = () => (
	<JSErrorBoundary
		id="asyncChecklistButtonComponent"
		packageName="jiraSoftwareOnboardingQuickstart"
		teamName="jlove-makkuro"
		fallback="unmount"
		attributes={addChunkLoadErrorAnalyticsAttribute}
	>
		<Placeholder name="software-onboarding-checklist-button-new" fallback={<FallbackButton />}>
			<LazyOpenButton />
		</Placeholder>
	</JSErrorBoundary>
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyOpenMenuItem = lazy<typeof OpenMenuItemType>(() =>
	import(
		/* webpackChunkName: "async-software-onboarding-checklist-open-menu-item" */ './open-menu-item'
	).then(({ OpenMenuItem }) => {
		openMenuItemLoadTime.mark(PERFORMANCE_MARK.BUNDLE_LOAD_END);
		return OpenMenuItem;
	}),
);

const SubmitOpenMenuItemLoadStart = () => {
	useLayoutEffect(() => {
		openMenuItemLoadTime.start();
		openMenuItemLoadTime.mark(PERFORMANCE_MARK.BUNDLE_LOAD_START);
	}, []);

	return <></>;
};

export const OpenMenuItem = (props: Props) => (
	<JSErrorBoundary
		id="asyncOpenMenuItemComponent"
		packageName="jiraSoftwareOnboardingQuickstart"
		teamName="jlove-makkuro"
		fallback="unmount"
		attributes={addChunkLoadErrorAnalyticsAttribute}
	>
		<Placeholder
			name="software-onboarding-checklist-open-menu-item"
			fallback={<SubmitOpenMenuItemLoadStart />}
		>
			<LazyOpenMenuItem {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
