import React from 'react';
import {
	AppSwitcher as AKAppSwitcher,
	AppSwitcherNav4 as AKAppSwitcherNav4,
} from '@atlaskit/atlassian-navigation';
import {
	SkeletonSwitcherButton,
	Nav4SkeletonSwitcherButton,
} from '@atlaskit/atlassian-navigation/skeleton-switcher-button';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';

type Props = {
	onOpen?: () => void;
	onMouseEnter?: () => void;
};

export const AppSwitcherLight = ({ onOpen, onMouseEnter }: Props) => {
	if (__SERVER__) {
		if (getWillShowNav4()) {
			return <Nav4SkeletonSwitcherButton label="" />;
		}
		return <SkeletonSwitcherButton label="" />;
	}

	if (getWillShowNav4()) {
		// @ts-expect-error - TS2741 - Property 'tooltip' is missing in type '{ onClick: (() => void) | undefined; }' but required in type 'BaseIconButtonProps'.
		return <AKAppSwitcherNav4 onClick={onOpen} onMouseEnter={onMouseEnter} />;
	}
	// @ts-expect-error - TS2741 - Property 'tooltip' is missing in type '{ onClick: (() => void) | undefined; }' but required in type 'BaseIconButtonProps'.
	return <AKAppSwitcher onClick={onOpen} onMouseEnter={onMouseEnter} />;
};
