import React from 'react';
import { N0 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	FireScreenAnalytics,
	ContextualAnalyticsData,
	INLINE_DIALOG,
} from '@atlassian/jira-product-analytics-bridge';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import messages from './messages';

export const UsingNotificationsToImproveAdminApprovalSpotlight = ({
	onViewRequests,
	onClose,
}: {
	onViewRequests: () => void;
	onClose: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { isSiteAdmin } = useTenantContext();

	return (
		<ContextualAnalyticsData
			sourceType={INLINE_DIALOG}
			sourceName="requestSpotlight"
			attributes={{
				isSiteAdmin,
			}}
		>
			<>
				{/* Event registry - https://data-portal.internal.atlassian.com/analytics/registry/52379 */}
				<FireScreenAnalytics />
				<JiraSpotlight
					actions={[
						{
							onClick: onViewRequests,
							text: formatMessage(messages.uniapaSpotlightViewRequestsButtonText),
						},
						{
							onClick: onClose,
							text: formatMessage(messages.uniapaSpotlightCloseButtonText),
							appearance: 'subtle',
						},
					]}
					target="admin-request-spotlight"
					key="admin-request-spotlight"
					targetRadius={100}
					dialogPlacement="bottom right"
					dialogWidth={275}
					targetBgColor={token('color.icon.inverse', N0)}
					messageId="cross-flow-using-notifications-to-improve-admin-approval.ui.jira-spotlight.admin-request-spotlight"
					messageType="engagement"
				>
					{formatMessage(messages.uniapaSpotlightNotificationText)}
				</JiraSpotlight>
			</>
		</ContextualAnalyticsData>
	);
};
