import React, { useCallback } from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import type { AppSwitcher as AppSwitcherType } from './main';
import type { AppSwitcherProps } from './types';
import { AppSwitcherLight } from './ui/app-switcher-light';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyAppSwitcher = lazyAfterPaint<typeof AppSwitcherType>(
	() =>
		import(/* webpackChunkName: "async-atlassian-navigation-app-switcher" */ './main').then(
			(module) => module.AppSwitcher,
		),
	{
		ssr: false,
	},
);

export const AppSwitcher = (props: AppSwitcherProps) => {
	const handleMouseEnter = useCallback(() => {
		LazyAppSwitcher.preload();
	}, []);

	return (
		<ErrorBoundary id="app-switcher.integration.async" packageName="NavigationIntegrations">
			<Placeholder name="lazy-app-switcher" fallback={<AppSwitcherLight />}>
				{props.isOpen ? (
					<LazyAppSwitcher {...props} />
				) : (
					<AppSwitcherLight onOpen={props.onOpen} onMouseEnter={handleMouseEnter} />
				)}
			</Placeholder>
		</ErrorBoundary>
	);
};
