import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { createStore, createHook, type Action } from '@atlassian/react-sweet-state';
import type { AppDetail, AppsInstalled, State } from './types';

const PLUGINS_URL = '/rest/plugins/1.0/available';
const PLUGINS_INSTALLED_BASE_URL = '/rest/plugins/1.0';

const cachedResult: {
	[key: string]: AppDetail;
} = {};

export const fetchInstalledAppDetails = (appKeys: string[]): Promise<AppDetail | undefined>[] => {
	return appKeys.map((appKey) =>
		cachedResult[appKey]
			? Promise.resolve(cachedResult[appKey])
			: performGetRequest(`${PLUGINS_INSTALLED_BASE_URL}/${appKey}-key`)
					.then(() => {
						cachedResult[appKey] = {
							key: appKey,
							installed: true,
						};
						return cachedResult[appKey];
					})
					.catch((error) => {
						if (error.response && error.response.status === 404) {
							cachedResult[appKey] = {
								key: appKey,
								installed: false,
							};
							return cachedResult[appKey];
						}

						log.safeErrorWithoutCustomerData(
							'experimental-apps-discover',
							`failed to load installed app details for ${appKey}`,
						);
					}),
	);
};

export const fetchAppDetails = (appKeys: string[]): Promise<AppDetail | undefined>[] =>
	// @ts-expect-error - TS2322 - Type 'Promise<void | AppDetail>[]' is not assignable to type 'Promise<AppDetail | undefined>[]'.
	appKeys.map((appKey) =>
		cachedResult[appKey]
			? Promise.resolve(cachedResult[appKey])
			: performGetRequest(`${PLUGINS_URL}/${appKey}-key`)
					.then((detail: AppDetail) => {
						cachedResult[appKey] = detail;
						return cachedResult[appKey];
					})
					.catch(() =>
						log.safeErrorWithoutCustomerData(
							'experimental-apps-discover',
							`failed to load app details for ${appKey}`,
						),
					),
	);

const handleFetch = (appKeys: string[]) => {
	const fetchAppDetailsFn = fg('enable_migrate_upm_available_to_plugins')
		? fetchInstalledAppDetails
		: fetchAppDetails;

	// @ts-expect-error - TS7031 - Binding element 'setState' implicitly has an 'any' type. | TS7031 - Binding element 'getState' implicitly has an 'any' type.
	return async ({ setState, getState }) => {
		const { isFetching } = getState();
		if (!isFetching) {
			try {
				const integrations: AppsInstalled = (await Promise.all(fetchAppDetailsFn(appKeys)))
					.filter(Boolean) // eslint-disable-next-line @typescript-eslint/no-explicit-any
					.reduce<Record<string, any>>(
						(apps, { key, installed }) => ({
							// eslint-disable-next-line jira/js/no-reduce-accumulator-spread
							...apps,
							[key]: installed,
						}),
						{},
					);
				setState({
					integrations,
					isFetching: false,
					fetchError: null,
					hasFetchedOnce: true,
					hasSuccessOnce: true,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				setState({
					isFetching: false,
					fetchError: error,
					hasFetchedOnce: true,
				});
			}
		}
	};
};

const actions = {
	fetchIntegrations:
		(appKeys: string[]): Action<State> =>
		async ({ setState, dispatch }) => {
			const promise = dispatch(handleFetch(appKeys));

			setState({
				isFetching: true,
				// @ts-expect-error - TS2322 - Type 'Promise<void>' is not assignable to type 'Promise<undefined>'.
				promise,
			});
		},
} as const;

const Store = createStore({
	name: 'fetch-integrations',
	initialState: {
		integrations: {},
		isFetching: false,
		fetchError: null,
		promise: null,
		hasFetchedOnce: false,
		hasSuccessOnce: false,
	},
	// @ts-expect-error - TS2322 - Type '{ readonly fetchIntegrations: (appKeys: string[]) => Action<State>; }' is not assignable to type 'Record<string, ActionThunk<{ integrations: {}; isFetching: boolean; fetchError: null; promise: null; hasFetchedOnce: boolean; hasSuccessOnce: boolean; }, { readonly fetchIntegrations: (appKeys: string[]) => Action<State, void, void | Promise<...>>; }>>'.
	actions,
});

export type Actions = typeof actions;

export const useFetchIntegrations = createHook(Store);

export default useFetchIntegrations;
