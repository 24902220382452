import React, { useEffect } from 'react';
import {
	CmdbUpsellPageContainer,
	CmdbUpsellPageStoreSubscriber,
	useUserHasDismissed,
} from '@atlassian/jira-cmdb-upsell-page/src/controllers';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { isCMDBEnabledInEnvironment } from '@atlassian/jira-servicedesk-cmdb-fedramp';
import { isLicensedPremiumUser } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/get-data/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import PopupBoxWrapper from '../../common/ui/popup-box-wrapper';
import { InsightMenuItem } from './main';

export const useShowAssets = () => {
	const tenantContext = useTenantContext();
	const { appPermissions } = tenantContext;

	const isJSMUser = appPermissions.hasServiceDeskAccess;

	if (isCMDBEnabledInEnvironment()) {
		if (isJSMUser) {
			return true;
		}
	}
	return false;
};

const Insight = () => {
	const tenantContext = useTenantContext();
	const { appPermissions } = tenantContext;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isPremiumUser = isLicensedPremiumUser(tenantContext);
	const isJSMUser = appPermissions.hasServiceDeskAccess;

	return (
		<JSErrorBoundary id="InsightNavMenu" packageName="jiraAtlassianNavigation" fallback="unmount">
			{!isPremiumUser ? (
				<CmdbUpsellPageContainer isGlobal atlassianAccountId={tenantContext.atlassianAccountId}>
					<CmdbUpsellPageStoreSubscriber>
						{({ userHasDismissed }) => {
							const showUpsell = userHasDismissed === false && isJSMUser;
							if (showUpsell) {
								// fire analytics event only for non-premium users that haven't dimissed the Assets upsell page button.
								fireTrackAnalytics(createAnalyticsEvent({}), 'cmdbUpsellPageButton viewed', {
									serviceDeskEdition: tenantContext.appEditions?.serviceDesk,
									isSiteAdmin: tenantContext.isSiteAdmin,
								});
							}
							return showUpsell && <InsightMenuItem />;
						}}
					</CmdbUpsellPageStoreSubscriber>
				</CmdbUpsellPageContainer>
			) : (
				<InsightMenuItem />
			)}
		</JSErrorBoundary>
	);
};

const NewInsight = () => {
	const tenantContext = useTenantContext();
	const { appPermissions } = tenantContext;
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isPremiumUser = isLicensedPremiumUser(tenantContext);
	const isJSMUser = appPermissions.hasServiceDeskAccess;
	const [hasDismissed] = useUserHasDismissed();

	const showUpsell = hasDismissed === false && isJSMUser;

	useEffect(() => {
		if (!isPremiumUser && showUpsell) {
			// fire analytics event only for non-premium users that haven't dimissed the Assets upsell page button.
			fireTrackAnalytics(createAnalyticsEvent({}), 'cmdbUpsellPageButton viewed', {
				serviceDeskEdition: tenantContext.appEditions?.serviceDesk,
				isSiteAdmin: tenantContext.isSiteAdmin,
			});
		}
	}, [
		createAnalyticsEvent,
		isPremiumUser,
		showUpsell,
		tenantContext.appEditions?.serviceDesk,
		tenantContext.isSiteAdmin,
	]);

	return (
		<JSErrorBoundary id="InsightNavMenu" packageName="jiraAtlassianNavigation" fallback="unmount">
			{(!isPremiumUser && showUpsell) || isPremiumUser ? <InsightMenuItem /> : null}
		</JSErrorBoundary>
	);
};

const InsightWihErrorBoundary = () => {
	const atlassianAccountId = useAccountId();
	if (fg('ttvc_global_navigation_improvement_batch_2')) {
		return (
			<TopLevelErrorBoundary id={MENU_ID.INSIGHT}>
				<CmdbUpsellPageContainer isGlobal atlassianAccountId={atlassianAccountId}>
					<PopupBoxWrapper>
						<NewInsight />
					</PopupBoxWrapper>
				</CmdbUpsellPageContainer>
			</TopLevelErrorBoundary>
		);
	}

	return (
		<TopLevelErrorBoundary id={MENU_ID.INSIGHT}>
			<PopupBoxWrapper>
				<Insight />
			</PopupBoxWrapper>
		</TopLevelErrorBoundary>
	);
};

export { InsightWihErrorBoundary as Insight };
