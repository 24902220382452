import React from 'react';
import { lazyAfterPaint } from 'react-loosely-lazy';
import type ThemeMenu from '@atlassian/jira-atlassian-navigation-theme-menu/src/ui/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import TriggerButton from './trigger-button';

export const LazyComponent = lazyAfterPaint<typeof ThemeMenu>(
	() =>
		import(
			/* webpackChunkName: "async-nav-theme-options-list-menu" */ '@atlassian/jira-atlassian-navigation-theme-menu'
		),
);

type Props = {
	testId?: string;
	itemPosition: number;
};

const AsyncThemeSwitcherItem = ({ testId, itemPosition }: Props) => (
	<Placeholder
		name="nav-theme-options-list-menu"
		fallback={<TriggerButton testId={testId} itemPosition={itemPosition} isDisabled />}
	>
		<LazyComponent
			renderTriggerButton={(props) => (
				<TriggerButton testId={testId} itemPosition={itemPosition} {...props} />
			)}
		/>
	</Placeholder>
);

export default AsyncThemeSwitcherItem;
