import { useEffect } from 'react';
import { useCommandPaletteIsOpen } from '@atlassian/jira-command-palette/src/controllers/command-palette/index.tsx';
import { createSwitchController } from '@atlassian/jira-switch-controller';
import type { MenuId } from '../../types';

const { SwitchContainer, useSwitch } = createSwitchController<MenuId | undefined>(
	'atlassian-navigation-top-menus',
);

export const TopMenusContainer = SwitchContainer;
const useTopMenusBase = useSwitch;

const useTopMenuWithCommandPalette: typeof useTopMenusBase = (props) => {
	const switcher = useTopMenusBase(props);
	const [{ isOpen: isCommandPaletteOpen }] = useCommandPaletteIsOpen();

	const [isSwitchOpen, { off }] = switcher;

	useEffect(() => {
		if (isSwitchOpen && isCommandPaletteOpen) off();
	}, [isCommandPaletteOpen, isSwitchOpen, off]);

	return switcher;
};

export { useTopMenuWithCommandPalette as useTopMenus };

export type UseTopMenusType = typeof useTopMenusBase;
