import React from 'react';
import Lozenge from '@atlaskit/lozenge';
import { useAddon } from '@atlassian/jira-addon-store';
import { ff } from '@atlassian/jira-feature-flagging';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ADDON_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import {
	getAppRequestsLink,
	getManagePromotionsLink,
} from '@atlassian/jira-platform-marketplace-url-resolver';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import {
	emceeAppRoute,
	manageAppsRoute,
} from '@atlassian/jira-router-routes-legacy-global-settings-apps-routes';
import { appRequestRoute, promotionsRoute } from '@atlassian/jira-router-routes-marketplace-routes';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { MenuItem } from '../../../../common/ui/menu/item';
import messages from './messages';
import type { FooterProps } from './types';

const emceeAppRequestsParams = { page: 'app-requests' } as const;
const emceeAppPromotionsParams = { page: 'promotions' } as const;

export const Footer = ({
	onUpdateIsFooterVisible,
	testIdPrefix,
	isFedrampEnvironment,
}: FooterProps) => {
	const { formatMessage } = useIntl();
	const tenantContext = useTenantContext();

	const { isAdmin, isSiteAdmin } = tenantContext;

	const [{ promise, hasFetchedOnce, items }] = useAddon();

	// Do not show Footer menu if in FedRamp environemnt
	if (isFedrampEnvironment) {
		return null;
	}

	if (promise && !hasFetchedOnce) {
		throw promise;
	}

	const { url: findAppsUrl } = items.find((item) => item.id === ADDON_ID.DISCOVER) || {};
	let position = 0;

	if (!findAppsUrl && !isAdmin && !isSiteAdmin) {
		onUpdateIsFooterVisible && onUpdateIsFooterVisible(false);
	}

	const testId = testIdConcat(testIdPrefix, 'items');

	return (
		<span data-testid={testId}>
			<MenuItem
				analytics={{
					actionSubjectId: 'appsNavigationMenuItem',
					isAdmin: Boolean(isSiteAdmin),
					menuItemId: 'newApps',
					menuItemType: 'action',
					position: position++,
				}}
				href="/jira/marketplace/discover?source=apps-menu"
				testId={testIdConcat(testId, 'item-explore-new-apps')}
			>
				{formatMessage(messages.exploreApps)}
			</MenuItem>
			{(isAdmin || isSiteAdmin) && (
				<MenuItem
					analytics={{
						actionSubjectId: 'appsNavigationMenuItem',
						isAdmin: isSiteAdmin,
						menuItemId: 'manageApps',
						menuItemType: 'action',
						position: position++,
					}}
					href="/plugins/servlet/upm"
					iconAfter={
						// eslint-disable-next-line jira/ff/no-preconditioning
						fg('enable-app-update-lozenge') &&
						tenantContext?.appUpdatesAvailable &&
						tenantContext.appUpdatesAvailable > 0 ? (
							<Lozenge appearance="new">
								{`${tenantContext.appUpdatesAvailable > 9 ? '9+' : tenantContext.appUpdatesAvailable} ${formatMessage(
									messages.appUpdates,
									{
										apps: tenantContext.appUpdatesAvailable,
									},
								)}`}
							</Lozenge>
						) : null
					}
					testId={testIdConcat(testId, 'item-manage-apps')}
					to={manageAppsRoute}
				>
					{formatMessage(messages.manageApps)}
				</MenuItem>
			)}
			{ff('enduser-emcee-discover') && isSiteAdmin && (
				<MenuItem
					analytics={{
						actionSubjectId: 'appsNavigationMenuItem',
						isAdmin: isSiteAdmin,
						menuItemId: 'appRequests',
						menuItemType: 'action',
						position: position++,
					}}
					href={getAppRequestsLink()}
					params={emceeAppRequestsParams}
					testId={testIdConcat(testId, 'item-app-requests')}
					to={ff('marketplace.emcee.native.spa.enable') ? appRequestRoute : emceeAppRoute}
				>
					{formatMessage(messages.appRequests)}
				</MenuItem>
			)}
			{ff('enable-app-promotions-emcee-apps-dropdown') && isSiteAdmin && (
				<MenuItem
					analytics={{
						actionSubjectId: 'appsNavigationMenuItem',
						isAdmin: isSiteAdmin,
						menuItemId: 'appPromotions',
						menuItemType: 'action',
						position: position++,
					}}
					href={getManagePromotionsLink()}
					params={emceeAppPromotionsParams}
					testId={testIdConcat(testId, 'item-app-promotions')}
					to={ff('marketplace.emcee.native.spa.enable') ? promotionsRoute : emceeAppRoute}
				>
					{formatMessage(messages.appPromotions)}
				</MenuItem>
			)}
			<ContextualAnalyticsData sourceName="appsMenuFooterSection" sourceType={SCREEN}>
				<FireScreenAnalytics />
			</ContextualAnalyticsData>
		</span>
	);
};
