import type React from 'react';
import type { HTMLAttributes, ReactElement, ReactNode, MouseEvent, KeyboardEvent } from 'react';
import type { AnalyticsAttributes } from '../analytics';

export type RenderAfterCommandsProps = {};

export type CommandPaletteBodyProps = {
	fallback?: DefaultFallback;
	results: FlattenedCommand[];
	total: number;
	renderAfterCommands?: React.FC<RenderAfterCommandsProps>;
};

// TODO: go/restrict-enums
// eslint-disable-next-line no-restricted-syntax
export enum CommandActionType {
	/** Performs action when user clicks or presses Enter in the command. Command Palette closes while performing the action */
	PERFORM = 'perform',
	/** Loads a sub list of commands  */
	COMMAND_LIST = 'command-list',
}

type CommandActionBase = {
	/** When true, the command palette will remain open but action will still be triggered */
	preventModalClose?: boolean;
	/** Analytics args added to navigation/secondaryAction commandPalette when action is triggered */
	analyticsArgs?: AnalyticsAttributes;
};

export type CommandActionPerform = CommandActionBase & {
	/** Specifies which type of command is being defined */
	type: CommandActionType.PERFORM;
	/** Performs action when user clicks or presses Enter in the command. Command Palette closes while performing the action */
	perform: () => void;
};

export type FallbackProps = {
	isNoResults?: boolean;
	isError?: boolean;
};

export type CommandActionCommandList = {
	type: CommandActionType.COMMAND_LIST;
	commands?: Command[];
	sections?: CommandSections;
	fallback?: DefaultFallback;
	keepSearch?: boolean;
	filterAndSortOverrideFn?: (
		search: string,
		commands: Command[],
		sections?: CommandSections,
	) => Command[];
	shouldShowNoCommandsWhileLoading?: boolean;
	onClick?: () => void;
	components?: {
		Wrapper?: React.FC<RenderModalWrapperProps>;
		Body?: React.FC<RenderChildrenProps>;
		Header?: React.FC<HeaderProps>;
	};
};

export type CommandAction = CommandActionPerform | CommandActionCommandList;

export type RenderChildrenProps = CommandPaletteBodyProps & {
	setChildResult: (result: ChildResult) => void;
	setLoading: (isLoading: boolean) => void;
	setFallbackOverride: (fallback?: DefaultFallback) => void;
};

export type CommandRelevancy = {
	/** Search relevance score - lower is better */
	searchRelevancyScore?: number;
	/** Name of the section the command is part of (used for search) */
	sectionName?: string;
	/** Keywords split into individual words to improve search performance */
	searchKeywordsArray?: string[];
};

export type Command = {
	/** Command ID has to be unique */
	id: CommandId;
	/** Command displayed in the command palette */
	name: string;

	/** Override components in the command palette */
	components?: {
		Name?: React.FC<NameProps>;
		CommandRow?: React.FC<CommandRowProps>;
		LeftIcon?: React.FC<LeftIconProps>;
		Footer?: React.FC<FooterProps>;
	};

	/** Shortcuts used to trigger this action (Command Palette does not trigger shortcuts automatically, this is just for visual and searching purposes) */
	shortcut?: string;
	/** Other words the user can find this command when searching for it */
	keywords?: string;
	/** Section the command is part of */
	section?: string;
	/** @deprecated TODO Delete this prop when cleaning isCommandPaletteDesignChangesEnabled */
	rightIcon?: () => JSX.Element;

	/** When mode is specified, the action of the command is triggered automatically when typing the mode characters */
	mode?: string;
	/** Sets the position of the command over others. The greater the number the lower position it will be */
	priority?: Priority;
	/** Change searchbar message to be the placeholder */
	placeholder?: string;

	primaryAction?: CommandAction;
	secondaryAction?: CommandAction;

	/** value to show as a search lozenge when displaying children list of commands. Falls back to command name if not specified */
	searchLozengeName?: string;

	/** Content to be rendered in the footer in place of the default footer */
	customFooterContent?: (defaultFooterContent?: ReactElement) => JSX.Element;

	/**
	 * Properties set in analytics will be send when the user triggers the command
	 * The `action` is the only required property as it will be used to control the `action` and the parent
	 * of the selected command
	 */
	analytics?: AnalyticsAttributes & {
		action?: string;
	};
};

/**
 * Used internally for command palette to treat commands received from the consumer
 */
export type CommandInternal = Command &
	CommandRelevancy & {
		/** Used to render multi-level commands, so names of parent and children are displayed at the same time */
		breadcrumb?: React.FC<NameProps>[];
		/** Used to add the parents of the current command to set the parent tree
		 * Index 0 is highest parent
		 */
		parentCommands?: Command[];
		/** value to show when cmd (for mac) and ctrl (for windows) is held to invoke the command */
		wasSecondaryTriggered?: boolean;
	};

export type ChildResult = {
	commands: Command[];
	sections?: CommandSections;
};

export type CommandId = string;

type CommandSectionRaw = {
	id?: string; // TODO enforce this eventually
	name: string;
	renderName: () => JSX.Element;
	priority?: Priority;
	/** When actionCategory is set, the command under this section will have the actionCategory set in the analytics */
	actionCategory?: string;
};

export type CommandSection =
	| (Omit<CommandSectionRaw, 'renderName'> & { renderName?: never })
	| (Omit<CommandSectionRaw, 'name'> & { name?: never });

export type CommandSections = {
	[id: string]: CommandSection;
};

export type Priority = number;

export type FallbackLabel = {
	/** string to be rendered in case of a fallback. Pass in null to not render the default fallback message if there is one */
	label?: string | null;
	/** component to be rendered in case of a fallback */
	renderLabel?: (() => JSX.Element) | null;
};

export type FallbackCommand = {
	/** command to be rendered in case of a fallback. Pass in null to not render the default fallback command if there is one */
	fallbackCommand?: Command | null;
	/** section to be rendered over the command */
	fallbackSection?: CommandSection | null;
};

export type FallbackMessage =
	| (Omit<FallbackLabel, 'label'> & { label?: never })
	| (Omit<FallbackLabel, 'renderLabel'> & { renderLabel?: never });

export type FallbackOptions = FallbackCommand & FallbackMessage;

export type DefaultFallback = {
	/** Fallback command and messages in case of error */
	onError?: FallbackMessage;
	/** Fallback command and messages in case of no results */
	onNoResults?: FallbackOptions;
};
export const COMMAND_ACTIONS: readonly CommandActionType[] = Object.values(CommandActionType);
export const COMMAND_WITH_CHILDREN = [CommandActionType.COMMAND_LIST] as const;

/** Use Matches types */
export type ListedCommand = {
	index?: number; // global index - excluding HeaderGroup
	indexWithinSection?: number;
	sectionIndex?: number;
	sectionId?: string;

	active?: boolean;
	isGroup?: false;
	command: CommandInternal;
};

type OneOf<T, Keys extends keyof T = keyof T> = Omit<T, Keys> &
	{
		[K in Keys]: Pick<T, K> & {
			[M in Exclude<Keys, K>]?: never;
		};
	}[Keys];

type RawGroupName = {
	group: string;
	renderGroup: () => JSX.Element;
};

type GroupName = OneOf<RawGroupName, 'group' | 'renderGroup'>;

export type HeaderGroup = GroupName & {
	index?: number;
	id?: string;
	section?: CommandSection;
	isGroup: true;
};

export type OrderedFilteredCommands = {
	grouped: (GroupName & {
		id?: string;
		priority?: number;
		commands: ListedCommand[];
	})[];
	ungrouped: ListedCommand[];
	length: number;
};

export type FlattenedCommand = HeaderGroup | ListedCommand;

export type CommandMatches = {
	results: FlattenedCommand[];
	total: number;
	activeCommand: CommandInternal | undefined;
};

// Components
export type NameProps = {
	isActive?: boolean;
	children?: ReactNode;
};

export type CommandRowProps = {
	index: number;
	command: Command;
	isActive: boolean;
	isFirstCommand?: boolean;
	onClick?: (event: MouseEvent | KeyboardEvent) => void;
	onMouseMove?: (event: MouseEvent) => void;
	onMouseDown?: (event: MouseEvent) => void;
	analyticsAttributes?: AnalyticsAttributes;
} & HTMLAttributes<HTMLDivElement>;

export type LeftIconProps = {};

export type FooterProps = {
	defaultFooterContent?: ReactElement;
	children?: ReactNode;
};

export type HeaderProps = {
	children?: ReactNode;
};

export type RenderModalWrapperProps = { children: ReactNode };
