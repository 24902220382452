import React from 'react';
import { usePersonalizationProvider } from '@atlassian/jira-business-sample-project/src/services/personalization/index.tsx';
import { useFavoriteProjects as useFavoriteProjectsDI } from '@atlassian/jira-favorites-store';
import { useCreateProjectPermission as useCreateProjectPermissionDI } from '@atlassian/jira-nav-item-service/src/services/create-project-permisson';
import { useRecentProjects as useRecentProjectsDI } from '@atlassian/jira-recents-store';
import { LoadStatus as LoadStatusCommonDI } from '../../../../common/ui/load-status/main.tsx';
import type { LoadStatusProps } from './types';

export const LoadStatus = ({
	LoadStatusCommon = LoadStatusCommonDI,
	shouldThrowError = false,
	// @ts-expect-error - TS2322 - Type 'HookFunction<State, BoundActions<State, { readonly fetchCreateProjectPermission: (baseUrl: string) => Action<State, void, void | Promise<void>>; }>, void>' is not assignable to type 'UseCreateProjectPermissionType'.
	useCreateProjectPermission = useCreateProjectPermissionDI,
	useFavorite = useFavoriteProjectsDI,
	useRecent = useRecentProjectsDI,
}: LoadStatusProps) => (
	<>
		<LoadStatusCommon
			shouldThrowError={shouldThrowError}
			useProvider={useCreateProjectPermission}
		/>
		<LoadStatusCommon shouldThrowError={shouldThrowError} useProvider={useFavorite} />
		<LoadStatusCommon shouldThrowError={shouldThrowError} useProvider={useRecent} />
		<LoadStatusCommon shouldThrowError={false} useProvider={usePersonalizationProvider} />
	</>
);
