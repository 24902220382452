import React from 'react';
import { styled } from '@compiled/react';
import { LinkItem as AkLinkItem } from '@atlaskit/menu';
import type { LinkItemProps } from '@atlassian/jira-navigation-types/src/types.tsx';
import { useNavigate } from './utils/navigate/main.tsx';

export const LinkItem = ({ href, target, onClick, ...rest }: LinkItemProps) => {
	const navigate = useNavigate({ href, onClick, target });

	return (
		<LinkComponent
			href={href}
			target={target}
			rel={target === '_blank' ? 'noopener noreferrer' : ''}
			onClick={navigate}
			{...rest}
		/>
	);
};

// eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LinkComponent = styled(AkLinkItem)({
	'&:focus, &:hover, &:active': {
		color: 'inherit',
		textDecoration: 'inherit',
	},
});
