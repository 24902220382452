import type { ProjectType } from '@atlassian/jira-common-constants';
import {
	CORE_PROJECT,
	SOFTWARE_PROJECT,
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { getProductEdition } from '@atlassian/jira-common-editions/src/get-product-edition';
import type { useIntlV2 as Intl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import {
	SOFTWARE,
	SERVICE_DESK,
	CORE,
	PRODUCT_DISCOVERY,
	type ApplicationKey,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import { FREE_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import messages from './messages';
import type { MenuItemInfo } from './types';

const isFreeEdition = (product: ApplicationKey, tenantContext: TenantContext): boolean => {
	const productEdition = getProductEdition(product, tenantContext);
	return productEdition === FREE_EDITION;
};

const productIsJSMOrJSW = (product: ApplicationKey): boolean =>
	product === SOFTWARE || product === SERVICE_DESK;

const hasFreeJSMOrJSW = (
	explicitlyLicensedProducts: ApplicationKey[],
	tenantContext: TenantContext,
): boolean =>
	explicitlyLicensedProducts.some(
		(product) => productIsJSMOrJSW(product) && isFreeEdition(product, tenantContext),
	);

export const shouldRenderMenuItem = (
	isSiteAdmin: boolean,
	product: ApplicationKey | null,
	explicitlyLicensedProducts: ApplicationKey[],
	tenantContext: TenantContext,
) => {
	if (!isSiteAdmin) {
		return false;
	}

	if (!product) {
		// If we're on a product-agnostic page (e.g "Your Work") we can still render the menu item
		// as long as there is a free JSM or JSW. The menu item will link to the "Manage Subscriptions" page.
		return hasFreeJSMOrJSW(explicitlyLicensedProducts, tenantContext);
	}

	// If we're in JSM or JSW Free, render the menu item.
	if (product && productIsJSMOrJSW(product)) {
		return isFreeEdition(product, tenantContext);
	}

	return false;
};

export const getFreeEditionMenuItem = (intl: typeof Intl, trialLength: number): MenuItemInfo => ({
	heading: intl().formatMessage(messages.upgrade),
	content: intl().formatMessage(messages.tryStandardPlan),
	tagContent: intl().formatMessage(messages.trialPeriod, {
		trialLength,
	}),
	lozengeAppearance: 'inprogress',
	touchpointId: 'preTrialMenu',
	flowId: 'upgradeFlow',
	displayUpflowModal: true,
});

export const getJiraProductKey = (
	subProduct: string | null,
	explicitlyLicensedProducts: ApplicationKey[],
): ApplicationKey | null => {
	if (subProduct === 'software') {
		return SOFTWARE;
	}
	if (subProduct === 'serviceDesk') {
		return SERVICE_DESK;
	}
	if (subProduct === 'core') {
		return CORE;
	}

	// If subProduct is null, we may be on a product-agnostic page e.g. "Your Work"
	// In there is only one licensed product, we can infer what the user is using
	if (explicitlyLicensedProducts.length === 1) {
		return explicitlyLicensedProducts[0];
	}

	return null;
};

export const transformProjectTypeToApplicationKey = (projectType: ProjectType | undefined) => {
	switch (projectType) {
		case SOFTWARE_PROJECT:
			return SOFTWARE;
		case SERVICE_DESK_PROJECT:
			return SERVICE_DESK;
		case CORE_PROJECT:
			return CORE;
		case PRODUCT_DISCOVERY_PROJECT:
			return PRODUCT_DISCOVERY;
		default:
			return null;
	}
};

export const getActivePageApplicationKey = (
	projectType: ProjectType | undefined,
	explicitlyLicensedProducts: ApplicationKey[],
): ApplicationKey | null => {
	const applicationKey = transformProjectTypeToApplicationKey(projectType);
	if (applicationKey !== null) {
		return applicationKey;
	}

	// If applicationKey is null, we may be on a product-agnostic page e.g. "Your Work"
	// In there is only one licensed product, we can infer what the user is using
	if (explicitlyLicensedProducts.length === 1) {
		return explicitlyLicensedProducts[0];
	}

	return null;
};

export const getHref = (freeEditionMenuItemInfo: MenuItemInfo, product: ApplicationKey | null) => {
	if (!product) {
		return '/admin/billing/applications';
	}

	return freeEditionMenuItemInfo.href
		? freeEditionMenuItemInfo.href(freeEditionMenuItemInfo.touchpointId)
		: '';
};
