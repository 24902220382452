import React, { useCallback, useMemo, type ReactNode, type SyntheticEvent } from 'react';
import Popup from '@atlaskit/popup';
import { EditionAwarenessButton } from '@atlassian/growth-pattern-library-edition-awareness-button';
import { InvitePeopleModalContextProvider } from '@atlassian/invite-people/InvitePeopleModalContext';
import type { CanonicalId } from '@atlassian/jira-common-constants/src/project-types';
import { editionAwarenessDropdownEntryPoint } from '@atlassian/jira-edition-awareness-dropdown/entrypoint';
import {
	FEATURE_INVITE_PEOPLE,
	type ApplicationProps,
	type SupportedApplicationEdition,
} from '@atlassian/jira-edition-awareness-dropdown/src/common/types';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { useBoolean } from '@atlassian/jira-platform-react-hooks-use-boolean/src/common/utils/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	ContextualAnalyticsData,
	FireScreenAnalytics,
	useAnalyticsEvents,
	DROPDOWN,
	// fireTrackAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import { useFetchOnboardingReverseTrial } from '@atlassian/jira-reverse-trial-utils/src/controllers/use-fetch-onboarding-reverse-trial';
import { useForceOpenPremiumPopupOnDay3 } from '@atlassian/jira-reverse-trial/src/controllers/use-force-open-premium-popup-on-day-3';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { EACommonAnalyticsAttributes } from '../../types';
import { PREMIUM_FEATURES_COHORT_VARIANT, firePremiumFeaturesExperimentExposure } from './utils';

/**
 * This was a capability developed during reverse trials where we automatically open the dialog after
 * 3 days of being on the trial. This happens for all customers that have a premium trial on sign up.
 */
const useAutoOpenPopover = (
	openDialog: () => void,
	analyticsAttributes?: EACommonAnalyticsAttributes,
) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onPopupToggle = useCallback(() => {
		// this is just used when we are opening the dialog 3 days after the customer
		// has started their trial, to force it open without the customers interaction
		openDialog();

		fireUIAnalytics(
			createAnalyticsEvent({ action: 'toggled', actionSubject: 'popup' }),
			'editionAwarenessTrialPillPopup',
			{
				...analyticsAttributes,
				flagKey: 'demonstrating_the_value_of_jira_premium_features',
				autoOpened: true,
				premiumFeaturesCohort: PREMIUM_FEATURES_COHORT_VARIANT,
			},
		);
	}, [openDialog, createAnalyticsEvent, analyticsAttributes]);

	const { premiumTrialOnSignup, isReady } = useFetchOnboardingReverseTrial();

	useForceOpenPremiumPopupOnDay3({
		shouldRun: Boolean(isReady && premiumTrialOnSignup),
		onPopupToggle,
	});
};

type DropdownProps = {
	productKey: CanonicalId;
	applicationEdition: SupportedApplicationEdition;
	children: ReactNode;
	status: 'default' | 'warning' | 'danger';
	analyticsAttributes?: EACommonAnalyticsAttributes;
	// this is a temporary prop to pass the trial end time to the dropdown
	// we shuold decide whether we want to keep this or not by confirming the source of the truth
	trialEndTime?: number;
};

export const Dropdown = ({
	productKey,
	applicationEdition,
	children,
	status,
	analyticsAttributes,
	trialEndTime,
}: DropdownProps) => {
	const cloudId = useCloudId();
	// While rendering this component, the project loading is already done
	// so we can skip the check for loading state
	const { data: project } = useProject();

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [dialogOpen, { on: openDialog, toggle: toggleDialog, off: closeDialog }] =
		useBoolean(false);

	const handleTogglePopup = useCallback(
		(_event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			const updatedEvent = analyticsEvent.update((payload) => ({
				...payload,
				action: 'toggled',
				actionSubject: 'popup',
			}));

			const show = !dialogOpen;

			fireUIAnalytics(updatedEvent, 'editionAwarenessTrialPillPopup', {
				...analyticsAttributes,
				show,
				flagKey: 'demonstrating_the_value_of_jira_premium_features',
				premiumFeaturesCohort: PREMIUM_FEATURES_COHORT_VARIANT,
			});

			if (show) {
				fireUIAnalytics(
					createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'button',
					}),
					'editionAwarenessTrialPill',
					{
						...analyticsAttributes,
						premiumFeaturesCohort: PREMIUM_FEATURES_COHORT_VARIANT,
					},
				);
			}

			toggleDialog();

			firePremiumFeaturesExperimentExposure({
				productKey,
				edition: applicationEdition,
				inGracePeriod: false,
				createAnalyticsEvent,
			});
		},
		[
			analyticsAttributes,
			applicationEdition,
			createAnalyticsEvent,
			dialogOpen,
			productKey,
			toggleDialog,
		],
	);

	const entryPointParams = useMemo(
		() => ({ cloudId, productKey, projectKey: project?.key }),
		[cloudId, productKey, project?.key],
	);

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		editionAwarenessDropdownEntryPoint,
		entryPointParams,
	);

	const loadEntryPoint = entryPointActions.load;
	const onAutoOpen = useCallback(() => {
		loadEntryPoint();
		openDialog();

		firePremiumFeaturesExperimentExposure({
			productKey,
			edition: applicationEdition,
			inGracePeriod: false,
			createAnalyticsEvent,
		});
	}, [applicationEdition, createAnalyticsEvent, loadEntryPoint, openDialog, productKey]);
	useAutoOpenPopover(onAutoOpen, analyticsAttributes);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions, true);

	const onFeatureClick: ApplicationProps['onFeatureClick'] = useCallback(
		(e, analyticsEvent, featureId) => {
			// We only want to close the dropdown if the user clicked on the invite people feature
			if (featureId === FEATURE_INVITE_PEOPLE) {
				handleTogglePopup(e, analyticsEvent);
			}
		},
		[handleTogglePopup],
	);

	const runtimeProps = useMemo(
		() => ({ productKey, applicationEdition, onFeatureClick, trialEndTime }),
		[productKey, applicationEdition, onFeatureClick, trialEndTime],
	);

	return (
		<ContextualAnalyticsData sourceName="editionAwareness" sourceType={DROPDOWN}>
			<InvitePeopleModalContextProvider>
				<Popup
					isOpen={dialogOpen}
					onClose={closeDialog}
					placement="bottom-end"
					content={() => (
						<JiraEntryPointContainer
							entryPointReferenceSubject={entryPointReferenceSubject}
							id="editionAwarenessDropdown"
							packageName="@atlassian/jira-navigation-apps-sidebar-edition-awareness"
							teamName="growth-tako"
							runtimeProps={runtimeProps}
							fallback={null}
						/>
					)}
					trigger={(triggerProps) => (
						<EditionAwarenessButton
							{...triggerProps}
							onClick={handleTogglePopup}
							ref={mergeRefs(triggerProps.ref, triggerRef)}
							status={status}
						>
							{children}
						</EditionAwarenessButton>
					)}
				/>
			</InvitePeopleModalContextProvider>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};
