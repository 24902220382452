import React, { useCallback } from 'react';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { useFavoriteDashboards as useFavorite } from '@atlassian/jira-favorites-store';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useRecentDashboards as useRecent } from '@atlassian/jira-recents-store';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import {
	DEFAULT_LOAD_COUNT_FAV,
	DEFAULT_LOAD_COUNT_RECENT,
	EXPERIENCE_NAVIGATION_TOP_MENU,
	NAVIGATION_ITEM_ID,
} from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';
import { MenuTrigger } from '../../common/ui/menu/trigger/main.tsx';
import PopupBoxWrapper from '../../common/ui/popup-box-wrapper';
import { Popup } from '../../common/ui/popup/main.tsx';
import { Menu } from './menu/main.tsx';
import messages from './messages';

const DashboardsAnonymous = () => {
	const { formatMessage } = useIntl();

	return (
		<MenuTrigger
			href="/jira/dashboards"
			menuId={MENU_ID.DASHBOARDS}
			navigationItemId={NAVIGATION_ITEM_ID.DASHBOARDS}
		>
			{formatMessage(messages.dashboards)}
		</MenuTrigger>
	);
};

const DashboardsLoggedIn = () => {
	const { formatMessage } = useIntl();
	const [{ hasFetchedOnce: hasFetchedOnceFavorites }, { loadFavoriteDashboards: loadFavorite }] =
		useFavorite();
	const [, { loadRecentDashboards: loadRecent }] = useRecent();
	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.DASHBOARDS,
		analyticsSource: 'atlassian-navigation',
	});

	const onBeforeMenuOpen = useCallback(() => {
		onStart();

		// refresh recents on open to add recently visited pages
		loadRecent(DEFAULT_LOAD_COUNT_RECENT);
		// load favourites only if not loaded already (optimisation to reduce requests)
		if (!hasFetchedOnceFavorites) loadFavorite(DEFAULT_LOAD_COUNT_FAV);
	}, [hasFetchedOnceFavorites, loadFavorite, loadRecent, onStart]);

	return (
		<PopupBoxWrapper>
			<Popup
				menuId={MENU_ID.DASHBOARDS}
				navigationItemId={NAVIGATION_ITEM_ID.DASHBOARDS}
				Menu={Menu}
				onBeforeMenuOpen={onBeforeMenuOpen}
				label={formatMessage(messages.dashboards)}
			>
				{formatMessage(messages.dashboards)}
			</Popup>
		</PopupBoxWrapper>
	);
};

const Dashboards = () => {
	const isAnonymous = useIsAnonymous();

	return isAnonymous ? <DashboardsAnonymous /> : <DashboardsLoggedIn />;
};

const DashboardsWithErrorBoundary = () => (
	<TopLevelErrorBoundary id={MENU_ID.DASHBOARDS}>
		<Dashboards />
	</TopLevelErrorBoundary>
);

export { DashboardsWithErrorBoundary as Dashboards };
