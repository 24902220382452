import { useEffect } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { createStore, createHook } from '@atlassian/react-sweet-state';

export type Response = { permitted: boolean };
export type StoreType = {
	loading: boolean;
	canManageUsers: boolean | undefined;
};

const initialState: StoreType = {
	loading: true,
	canManageUsers: undefined,
};

const Store = createStore({
	initialState,
	actions: {
		fetch:
			({
				orgId,
				accountId,
				isSiteAdmin,
			}: {
				orgId: string | undefined;
				accountId: string | null;
				isSiteAdmin: boolean;
			}) =>
			async ({ setState, getState }) => {
				if (getState().canManageUsers !== undefined) {
					// Shortcut - Don't fetch again if we already have the data
					return;
				}

				if (accountId == null || orgId == null) {
					// Shortcut - Anonymous users cannot manage users, and orgId is required
					setState({
						loading: false,
						canManageUsers: false,
					});
					return;
				}

				if (isSiteAdmin) {
					// Shortcut - Site admins can manage users
					setState({
						loading: false,
						canManageUsers: true,
					});
					return;
				}

				setState({ loading: true });
				try {
					const { permitted } = await performPostRequest<Response>(
						'/gateway/api/permissions/permitted',
						{
							body: JSON.stringify({
								resourceId: `ari:cloud:platform::org/${orgId}`,
								principalId: `ari:cloud:identity::user/${accountId}`,
								permissionId: 'manage-users',
							}),
						},
					);
					setState({
						loading: false,
						canManageUsers: permitted,
					});
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (error: any) {
					// Fail safe - if we can't fetch the data, assume the user can't manage users
					setState({
						loading: false,
						canManageUsers: false,
					});
					fireErrorAnalytics({
						meta: {
							id: 'fetchManageUsersPermission',
							packageName: 'jiraAtlassianNavigation',
							teamName: 'navigation',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
				}
			},
	},
	name: 'ManageUsersPermissionInternal',
});

const useManageUsersPermissionInternal = createHook(Store);

export const useManageUsersPermission = (): StoreType => {
	const { data: orgId } = useOrgId();
	const accountId = useAccountId();
	const isSiteAdmin = useIsSiteAdmin();
	const [state, { fetch }] = useManageUsersPermissionInternal();

	useEffect(() => {
		fetch({ accountId, orgId, isSiteAdmin });
	}, [fetch, accountId, orgId, isSiteAdmin]);

	return state;
};
