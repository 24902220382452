import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { MenuInfo } from '../info/main.tsx';
import defaultErrorImg from './assets/generic-error.svg';
import messages from './messages';
import type { MenuErrorProps } from './types';

export const MenuError = ({ testIdPrefix }: MenuErrorProps) => {
	const { formatMessage } = useIntl();
	const errorText = (
		<TextWrapper>
			<Heading>{formatMessage(messages.errorHeading)}</Heading>
			<div>{formatMessage(messages.errorBody)}</div>
		</TextWrapper>
	);
	return (
		<MenuInfo
			imgAltText={formatMessage(messages.errorImg)}
			imgSrc={defaultErrorImg}
			testId={testIdConcat(testIdPrefix, 'error')}
			text={errorText}
			useSmallImage
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextWrapper = styled.div({
	padding: `0 ${token('space.300', '24px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled.strong({
	display: 'block',
	marginBottom: token('space.100', '8px'),
});
