import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Link from '@atlaskit/link';
import { Section } from '@atlaskit/menu';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import messages from './messages';
import type { WarningProps } from './types';

export const Warning = ({ testIdPrefix }: WarningProps) => {
	const { formatMessage } = useIntl();

	const testId = testIdConcat(testIdPrefix, 'warning');

	return (
		<Section testId={testId}>
			<Wrapper>
				<FormattedMessage
					{...messages.noAdminPermissionMessage}
					values={{
						contact: (
							<Link href="/secure/ContactAdministrators!default.jspa">
								{formatMessage(messages.noAdminPermissionMessageContact)}
							</Link>
						),
					}}
				/>
			</Wrapper>
		</Section>
	);
};

export const Wrapper = ({ children }: { children: ReactNode }) => (
	<Outer>
		<Inner>{children}</Inner>
	</Outer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Outer = styled.div({
	padding: `${token('space.100', '8px')} ${token('space.250', '20px')} ${token(
		'space.150',
		'12px',
	)} ${token('space.250', '20px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Inner = styled.p({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 50}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.warning', colors.Y50),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius,
	padding: token('space.200', '16px'),
});
