import React, { useCallback, useEffect } from 'react';
import { Section as SectionDI } from '@atlaskit/menu';
import { AuthenticationErrorBoundary as AuthenticationErrorBoundaryDI } from '@atlassian/jira-error-boundaries/src/ui/authentication-error-boundary/index.tsx';
import { JSErrorBoundary as JSErrorBoundaryDI } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useExperienceFail as useExperienceFailDI } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useFavoriteFilters as useFavoriteFiltersDI } from '@atlassian/jira-favorites-store';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import {
	DEFAULT_LOAD_COUNT_FAV,
	PACKAGE_NAME,
	EXPERIENCE_NAVIGATION_TOP_MENU,
} from '../../../common/constants';
import { MenuError as ErrorDI } from '../../../common/ui/menu/error/main.tsx';
import { MenuLayout as LayoutDI } from '../../../common/ui/menu/layout';
import { MenuSkeletonContent as MenuSkeletonContentDI } from '../../../common/ui/menu/skeleton/content/main.tsx';
import { MenuSkeletonFooter as MenuSkeletonFooterDI } from '../../../common/ui/menu/skeleton/footer/main.tsx';
import { Empty as EmptyDI } from './empty/main.tsx';
import { FavoriteContent as FavoriteContentDI } from './favorite-content/main.tsx';
import { Footer as FooterDI } from './footer/main.tsx';
import { LoadStatus as LoadStatusDI } from './load-status/main.tsx';
import { RecentContent as RecentContentDI } from './recent-content/main.tsx';
import type { MenuProps } from './types';

const EVENT_DATA = { id: MENU_ID.FILTERS } as const;

export const Menu = ({
	AuthenticationErrorBoundary = AuthenticationErrorBoundaryDI,
	Empty = EmptyDI,
	Error = ErrorDI,
	FavoriteContent = FavoriteContentDI,
	Footer = FooterDI,
	JSErrorBoundary = JSErrorBoundaryDI,
	Layout = LayoutDI,
	LoadStatus = LoadStatusDI,
	RecentContent = RecentContentDI,
	Section = SectionDI,
	SkeletonContent = MenuSkeletonContentDI,
	SkeletonFooter = MenuSkeletonFooterDI,
	testIdPrefix,
	useExperienceFail = useExperienceFailDI,
	useFavorite = useFavoriteFiltersDI,
}: MenuProps) => {
	const [, { loadFavoriteFilters: loadFavorite }] = useFavorite();
	const onFail = useExperienceFail({ experience: EXPERIENCE_NAVIGATION_TOP_MENU });

	useEffect(
		() => () => {
			// Refresh favourites on close to update if user has toggled any item
			loadFavorite(DEFAULT_LOAD_COUNT_FAV);
		},
		[loadFavorite],
	);

	const testIdContent = testIdConcat(testIdPrefix, 'content');
	const testIdFooter = testIdConcat(testIdPrefix, 'footer');

	const errorFallback = useCallback(
		() => <Error testIdPrefix={testIdContent} />,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[testIdContent],
	);

	return (
		<Layout isWide>
			<Section isScrollable>
				<JSErrorBoundary
					id={MENU_ID.FILTERS}
					packageName={PACKAGE_NAME}
					fallback={errorFallback}
					withExperienceTracker
					extraEventData={EVENT_DATA}
					onError={onFail}
				>
					<AuthenticationErrorBoundary render={errorFallback}>
						<Empty testIdPrefix={testIdContent}>
							<Placeholder
								name="atlassian-navigation-filters-menu"
								fallback={<SkeletonContent testIdPrefix={testIdContent} />}
							>
								<LoadStatus shouldThrowError />
								<FavoriteContent testIdPrefix={testIdContent} />
								<RecentContent testIdPrefix={testIdContent} />
							</Placeholder>
						</Empty>
					</AuthenticationErrorBoundary>
				</JSErrorBoundary>
			</Section>
			<Section hasSeparator>
				<Placeholder
					name="atlassian-navigation-filters-footer"
					fallback={<SkeletonFooter testIdPrefix={testIdFooter} />}
				>
					<LoadStatus />
					<Footer testIdPrefix={testIdFooter} />
				</Placeholder>
			</Section>
		</Layout>
	);
};
