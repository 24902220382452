// eslint-disable-next-line jira/restricted/react-component-props
import React, { type ComponentProps } from 'react';
import { render } from 'react-dom';
import { JiraContextContainer } from '@atlassian/jira-context-container';
import type { AsyncCustomFieldEdit } from '@atlassian/jira-forge-ui-async/src/ui/custom-fields/custom-field-edit/async';
import type { AsyncCustomField } from '@atlassian/jira-forge-ui-async/src/ui/custom-fields/custom-field/async';
import type {
	AsyncWorkflowConditionCreateEdit,
	AsyncWorkflowConditionView,
} from '@atlassian/jira-forge-ui-async/src/ui/workflow-condition/async';
import type {
	AsyncWorkflowPostFunctionCreateEdit,
	AsyncWorkflowPostFunctionView,
} from '@atlassian/jira-forge-ui-async/src/ui/workflow-post-function/async';
import type {
	AsyncWorkflowValidatorCreateEdit,
	AsyncWorkflowValidatorView,
} from '@atlassian/jira-forge-ui-async/src/ui/workflow-validator/async';
import { CustomField, CustomFieldEdit } from './custom-field';
import {
	WorkflowConditionCreate,
	WorkflowConditionEdit,
	WorkflowConditionView,
} from './workflow-condition';
import {
	WorkflowPostFunctionCreate,
	WorkflowPostFunctionEdit,
	WorkflowPostFunctionView,
} from './workflow-post-function';
import {
	WorkflowValidatorCreate,
	WorkflowValidatorEdit,
	WorkflowValidatorView,
} from './workflow-validator';

export const customField = {
	renderView: (
		props: JSX.LibraryManagedAttributes<
			typeof AsyncCustomField,
			ComponentProps<typeof AsyncCustomField>
		>,
	) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(<CustomField {...props} />, container);
		return container;
	},
	renderEditView: (
		props: JSX.LibraryManagedAttributes<
			typeof AsyncCustomFieldEdit,
			ComponentProps<typeof AsyncCustomFieldEdit>
		>,
	) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(<CustomFieldEdit {...props} />, container);
		return container;
	},
} as const;

type WorkflowConditionCreateEditElementConfig = JSX.LibraryManagedAttributes<
	typeof AsyncWorkflowConditionCreateEdit,
	ComponentProps<typeof AsyncWorkflowConditionCreateEdit>
>;
type WorkflowConditionViewElementConfig = JSX.LibraryManagedAttributes<
	typeof AsyncWorkflowConditionView,
	ComponentProps<typeof AsyncWorkflowConditionView>
>;

export const workflowCondition = {
	renderView: (props: WorkflowConditionViewElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowConditionView {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
	renderCreate: (props: WorkflowConditionCreateEditElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowConditionCreate {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
	renderEdit: (props: WorkflowConditionCreateEditElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowConditionEdit {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
} as const;

type WorkflowPostFunctionCreateEditElementConfig = JSX.LibraryManagedAttributes<
	typeof AsyncWorkflowPostFunctionCreateEdit,
	ComponentProps<typeof AsyncWorkflowPostFunctionCreateEdit>
>;
type WorkflowPostFunctionViewElementConfig = JSX.LibraryManagedAttributes<
	typeof AsyncWorkflowPostFunctionView,
	ComponentProps<typeof AsyncWorkflowPostFunctionView>
>;

export const workflowPostFunction = {
	renderView: (props: WorkflowPostFunctionViewElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowPostFunctionView {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
	renderCreate: (props: WorkflowPostFunctionCreateEditElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowPostFunctionCreate {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
	renderEdit: (props: WorkflowPostFunctionCreateEditElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowPostFunctionEdit {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
} as const;

type WorkflowValidatorCreateEditElementConfig = JSX.LibraryManagedAttributes<
	typeof AsyncWorkflowValidatorCreateEdit,
	ComponentProps<typeof AsyncWorkflowValidatorCreateEdit>
>;
type WorkflowValidatorViewElementConfig = JSX.LibraryManagedAttributes<
	typeof AsyncWorkflowValidatorView,
	ComponentProps<typeof AsyncWorkflowValidatorView>
>;

export const workflowValidator = {
	renderView: (props: WorkflowValidatorViewElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowValidatorView {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
	renderCreate: (props: WorkflowValidatorCreateEditElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowValidatorCreate {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
	renderEdit: (props: WorkflowValidatorCreateEditElementConfig) => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const container = document.createElement('div');
		render(
			<JiraContextContainer>
				<WorkflowValidatorEdit {...props} />
			</JiraContextContainer>,
			container,
		);
		return container;
	},
} as const;
