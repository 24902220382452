import { useCallback } from 'react';
import type { CanonicalId, ProjectType } from '@atlassian/jira-common-constants/src/project-types';
import { fg } from '@atlassian/jira-feature-gating';
import {
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { EntitlementDetails as TcsEntitlementDetails } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import type { BillingInformation } from '../../controllers/billing-information/types.tsx';
import type { EntitlementDetails } from './trial-pill-button/types';

type UseEligibilityCheckEventProps = {
	productKey: CanonicalId;
	projectType: ProjectType;
	edition: ApplicationEdition;
	entitlementDetails: EntitlementDetails | TcsEntitlementDetails | undefined;
};

export const useEligibilityCheckEvent = ({
	productKey,
	edition,
	entitlementDetails,
	projectType,
}: UseEligibilityCheckEventProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSiteAdmin = useIsSiteAdmin();

	return useCallback(
		(ineligibilityReason: string | null, attributes: Record<string, unknown> = {}) => {
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				'eligibilityCriteria checked',
				'editionAwarenessTrialPill',
				{
					ineligibilityReason,
					isSiteAdmin,
					productKey,
					projectType,
					edition,
					entitlementDetails,
					...attributes,
				},
			);
		},
		[createAnalyticsEvent, edition, entitlementDetails, isSiteAdmin, productKey, projectType],
	);
};

export const getIneligibilityReasons = (billingInformation: BillingInformation): string[] => {
	const ineligibilityReasons: string[] = [];

	if (billingInformation.rawResponse) {
		if (billingInformation.rawResponse?.tenantContexts == null) {
			ineligibilityReasons.push('missingTenantContexts');
			return ineligibilityReasons;
		}

		if (billingInformation.rawResponse?.tenantContexts[0] == null) {
			ineligibilityReasons.push('missingTenantContext');
			return ineligibilityReasons;
		}

		if (billingInformation.rawResponse?.tenantContexts[0]?.entitlementInfo == null) {
			ineligibilityReasons.push('missingEntitlementInfo');
			return ineligibilityReasons;
		}
	}

	if (billingInformation.notInTrialOrPredunning) {
		ineligibilityReasons.push('notInTrialOrPredunning');

		// Return early, as other reasons could not be determined as data are missing in the query
		return ineligibilityReasons;
	}

	// Billing admin explicitly checks false value excluding null/undefined to make sure we use it as reason only if it was intentionally populated
	if (billingInformation.isBillingAdmin !== true) {
		if (billingInformation.isBillingAdmin === false) {
			ineligibilityReasons.push('notBillingAdmin');
		} else {
			ineligibilityReasons.push('billingAdminIsNull');
		}
	}

	// Invoiceability is also explicitly checked for not being false since we are showing pill only to sites which were explicitly marked as not invoiceable
	// it is safer not to show pill in case the value comes as null or undefined then show it to ineligible user
	if (billingInformation.invoiceable !== false) {
		if (billingInformation.invoiceable === null) {
			ineligibilityReasons.push('invoiceableIsNull');
		} else {
			ineligibilityReasons.push('invoiceable');
		}
	}

	if (!fg('trial_edition_awareness_extra_eligibility_check')) {
		return ineligibilityReasons;
	}

	if (billingInformation.partnerManaged === true) {
		ineligibilityReasons.push('partnerManaged');
	}

	if (billingInformation?.billingInterval === 'YEAR') {
		ineligibilityReasons.push('annualBillingCycle');
	}

	if (
		billingInformation.pricingPlanType &&
		!['COMMERCIAL', 'COMMUNITY'].includes(billingInformation.pricingPlanType)
	) {
		ineligibilityReasons.push(`pricingType:${billingInformation.pricingPlanType}`);
	}

	if (['enterprise', 'atlassian-together'].includes(billingInformation.overriddenEdition || '')) {
		ineligibilityReasons.push(`overriddenEdition:${billingInformation.overriddenEdition}`);
	}

	return ineligibilityReasons;
};

// Used to support custom Error objects created by inheriting another Error object.
// Object.getOwnPropertyNames is used to fetch all properties from nested prototypes.
// We are removing the 'stack' as we are adding the transformed error into analytic events.
// The 'cause' property of an Error instance is also an Error instance indicating the
// specific original cause of the error, in which case we only want to keep the 'message'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformError = (error: any) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
	const errorObject = {} as any;
	Object.getOwnPropertyNames(error)
		.filter((name) => name !== 'stack')
		.forEach((name) => {
			if (name === 'cause') {
				errorObject[name] = error[name]?.message;
			} else {
				errorObject[name] = error[name];
			}
		});
	return errorObject;
};
