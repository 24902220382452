import React, { useEffect, type ComponentType } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import { PACKAGE_NAME } from '../../common/constants';
import {
	searchDialogMetric,
	SEARCH_DIALOG_DOWNLOAD_START_MARK,
	SEARCH_DIALOG_DOWNLOAD_END_MARK,
} from './performance-metrics';
import { Skeleton } from './skeleton';
import type { SearchProps as SearchPropsType } from './types';

const LoadingSkeleton = () => <Skeleton />;

const SearchDialogMetricStart = () => {
	useEffect(() => {
		searchDialogMetric.startFromPageLoad();
		searchDialogMetric.mark(SEARCH_DIALOG_DOWNLOAD_START_MARK);
	}, []);

	return null;
};

const SearchDialogMetricEnd = () => {
	useEffect(() => {
		searchDialogMetric.mark(SEARCH_DIALOG_DOWNLOAD_END_MARK);
	}, []);

	return null;
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazySearch = lazyForPaint<ComponentType<SearchPropsType>>(
	() =>
		import(/* webpackChunkName: "async-atlassian-navigation-search" */ './main').then(
			(m) => m.Search,
		),
	{ ssr: false },
);

export const Search = (props: SearchPropsType) => (
	<JSErrorBoundary id="search" packageName={PACKAGE_NAME} fallback="unmount">
		<SearchDialogMetricStart />
		<Placeholder name="lazy-search" fallback={<LoadingSkeleton />}>
			<LazySearch {...props} />
			<SearchDialogMetricEnd />
		</Placeholder>
	</JSErrorBoundary>
);

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { SearchProps } from './types';
