import { getSlackConsentUrl } from '../../services/get-consent-url/index.tsx';
import type { Window } from './types';

const WIDTH = 500;
const HEIGHT = 900;

const baseParams = {
	toolbar: 0,
	menubar: 0,
	location: 0,
	status: 0,
	scrollbars: 1,
	resizable: 0,
	chrome: 'yes',
} as const;

// @ts-expect-error - TS2322 - Type 'Window & typeof globalThis' is not assignable to type 'Window'.
const popupWindowParams = (w: Window = window) => {
	const width = Math.min(WIDTH, w.screen.availWidth);
	const height = Math.min(HEIGHT, w.screen.availHeight);

	const entities = {
		...baseParams,
		width,
		height,
		left: Math.round((w.innerWidth - width) / 2 + w.screenX),
		top: Math.round((w.innerHeight - height) / 3 + w.screenY),
	};
	return (
		Object.keys(entities)
			// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{ width: number; height: number; left: number; top: number; toolbar: 0; menubar: 0; location: 0; status: 0; scrollbars: 1; resizable: 0; chrome: "yes"; }'.
			.map((key) => `${key}=${entities[key]}`)
			.join(',')
	);
};

// @ts-expect-error - TS2322 - Type 'Window & typeof globalThis' is not assignable to type 'Window'.
export const openSlackIntegrationPopup = (source: string, w: Window = window) =>
	w.open(getSlackConsentUrl(source), '_blank', popupWindowParams(w));
