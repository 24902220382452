import React, { useCallback, useMemo } from 'react';
import { lazyForPaint } from 'react-loosely-lazy';
import { fg } from '@atlassian/jira-feature-gating';
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui';
import { LazyIframe } from '@atlassian/jira-forge-ui-iframe-generic';
import type { ForgeUIExtension } from '@atlassian/jira-forge-ui-renderers/src/ui/forge-ui-extension';
import type {
	WorkflowValidatorModule,
	WorkflowConditionModule,
	WorkflowPostFunctionModule,
} from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import { isCustomUIExtension } from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension';
import Placeholder from '@atlassian/jira-placeholder';
import type { LazyPropsWithExtensionDataEntryPoint, LazyRenderer } from '../../../types';
import { Skeleton } from '../skeleton/main.tsx';
import type {
	BridgeSubmitParam,
	PropsCreateEditValidator,
	PropsViewValidator,
	PropsViewCondition,
	PropsCreateEditCondition,
	PropsViewPostFunction,
	PropsCreateEditPostFunction,
} from './types';

const skeletonConfig = [{ width: 100 }, { width: 75 }];

export type PropsViewValidatorWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsViewValidator> & {
		module: WorkflowValidatorModule;
	};

export type PropsViewConditionWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsViewCondition> & {
		module: WorkflowConditionModule;
	};

export type PropsViewPostFunctionWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsViewPostFunction> & {
		module: WorkflowPostFunctionModule;
	};

type PropsView =
	| PropsViewValidatorWithModule
	| PropsViewConditionWithModule
	| PropsViewPostFunctionWithModule;

export type PropsCreateEditValidatorWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsCreateEditValidator> & {
		module: WorkflowValidatorModule;
	};

export type PropsCreateEditConditionWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsCreateEditCondition> & {
		module: WorkflowConditionModule;
	};

export type PropsCreateEditPostFunctionWithModule =
	LazyPropsWithExtensionDataEntryPoint<PropsCreateEditPostFunction> & {
		module: WorkflowPostFunctionModule;
	};

type PropsCreateEdit =
	| PropsCreateEditValidatorWithModule
	| PropsCreateEditConditionWithModule
	| PropsCreateEditPostFunctionWithModule;

const getModule = () =>
	import(
		/* webpackChunkName: "async-forge-ui-extension" */ '@atlassian/jira-forge-ui-renderers/src/ui/forge-ui-extension'
	);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const LazyForgeUIExtension: LazyRenderer<typeof ForgeUIExtension> = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.ForgeUIExtension), {
		ssr: false,
	}),
);

export const LazyWorkflowView = (props: PropsView) => {
	const { entryPoint, extension, extensionData, module, ...restProps } = props;
	const loadingComponent = <Skeleton skeletonConfig={skeletonConfig} />;
	const extraProps = {
		entryPoint,
		extension,
		loadingComponent,
		module,
		extensionData: {
			...extensionData,
			type: module,
			entryPoint,
		},
	};

	// https://switcheroo.atlassian.com/ui/gates/cd39682c-bc5b-4f4b-af87-f25672cfc326
	if (fg('jira_new_ui_kit_renderer')) {
		return (
			<Placeholder name="workflow-view" fallback={loadingComponent}>
				{isCustomUIExtension(extension, entryPoint) && (
					<LazyForgeUIExtension {...restProps} {...extraProps} />
				)}
			</Placeholder>
		);
	}

	return (
		<Placeholder name="workflow-view" fallback={loadingComponent}>
			{isCustomUIExtension(extension, entryPoint) && <LazyIframe {...restProps} {...extraProps} />}
		</Placeholder>
	);
};

export const LazyWorkflowCreateEdit = (props: PropsCreateEdit) => {
	const { entryPoint, extension, extensionData, module, onSetConfigurationCallback, ...restProps } =
		props;
	const loadingComponent = <Skeleton skeletonConfig={skeletonConfig} />;
	const extraProps = {
		entryPoint,
		extension,
		loadingComponent,
		module,
		extensionData: {
			...extensionData,
			type: module,
			entryPoint,
		},
	};

	const setWorkflowRuleConfigurationCallback = useCallback(
		(param: BridgeSubmitParam): void => {
			if (!onSetConfigurationCallback) {
				return;
			}
			const { onConfigure } = param?.data || {};
			const callbackType = typeof onConfigure;
			if (callbackType !== 'function') {
				throw new Error('Invalid "onConfigure" callback - function expected');
			}

			onSetConfigurationCallback(async () => {
				const config = await onConfigure();
				return config;
			});
		},
		[onSetConfigurationCallback],
	);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const customBridgeMethods: any = useMemo(
		() => ({ setWorkflowRuleConfigurationCallback }),
		[setWorkflowRuleConfigurationCallback],
	);

	// https://switcheroo.atlassian.com/ui/gates/cd39682c-bc5b-4f4b-af87-f25672cfc326
	if (fg('jira_new_ui_kit_renderer')) {
		return (
			<Placeholder name="workflow-create-edit" fallback={loadingComponent}>
				{isCustomUIExtension(extension, entryPoint) && (
					<LazyForgeUIExtension
						{...restProps}
						{...extraProps}
						customBridgeMethods={customBridgeMethods}
					/>
				)}
			</Placeholder>
		);
	}

	return (
		<Placeholder name="workflow-create-edit" fallback={loadingComponent}>
			{isCustomUIExtension(extension, entryPoint) && (
				<LazyIframe {...restProps} {...extraProps} customBridgeMethods={customBridgeMethods} />
			)}
		</Placeholder>
	);
};
