import React, { useCallback, useEffect, useMemo, type Ref } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { EditionAwarenessButton as Button } from '@atlassian/growth-pattern-library-edition-awareness-button';
import {
	projectTypeToCanonicalId,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types';
import { getProductOfferingKey } from '@atlassian/jira-growth-utils/src/services/get-product-offering-key/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointPressableTrigger } from '@atlassian/jira-modal-entry-point-pressable-trigger';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { editionAwarenessNextQuery } from '@atlassian/jira-relay/src/__generated__/editionAwarenessNextQuery.graphql';
import { getApplicationKeyForProject } from '@atlassian/jira-shared-types/src/application';
import {
	PRODUCT_DISCOVERY,
	SERVICE_DESK,
} from '@atlassian/jira-shared-types/src/application-key.tsx';
import {
	type ApplicationEdition,
	FREE_EDITION,
	STANDARD_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useProductEntitlementDetails } from '@atlassian/jira-tenant-context-controller/src/components/product-entitlement-details/index.tsx';
import { useShouldSeeButton } from '../../services/use-should-see-button';
import { editionAwarenessModalEntryPoint } from './entrypoint';
import messages from './messages';

type SupportedApplicationKey = typeof PRODUCT_DISCOVERY | typeof SERVICE_DESK;
type SupportedApplicationEdition = typeof FREE_EDITION;

const ApplicationChargeElement: Record<SupportedApplicationKey, 'user' | 'agent'> = {
	[PRODUCT_DISCOVERY]: 'user',
	[SERVICE_DESK]: 'agent',
};

const TargetOfferings: Record<SupportedApplicationEdition, ApplicationEdition> = {
	[FREE_EDITION]: STANDARD_EDITION,
};

type EditionAwarenessButtonProps = {
	applicationEdition: SupportedApplicationEdition;
	applicationKey: SupportedApplicationKey;
	projectType: ProjectType;
};

export const EditionAwarenessButton = ({
	applicationEdition,
	applicationKey,
	projectType,
}: EditionAwarenessButtonProps) => {
	const { formatMessage } = useIntl();

	const cloudId = useCloudId();
	const productKey = projectTypeToCanonicalId(projectType);
	const chargeElement = ApplicationChargeElement[applicationKey];
	const offeringKey = getProductOfferingKey(applicationKey, TargetOfferings[applicationEdition]);

	const data = useLazyLoadQuery<editionAwarenessNextQuery>(
		graphql`
			query editionAwarenessNextQuery(
				$cloudId: ID!
				$hamsProductKey: String!
				$chargeElement: String!
				$offeringKey: ID
			) {
				tenantContexts(cloudIds: [$cloudId])
					@optIn(to: ["CcpAllUserUpgradeAndPay", "HamsAllUserUpgradeAndPay"]) {
					entitlementInfo(hamsProductKey: $hamsProductKey) {
						entitlement {
							experienceCapabilities {
								changeOfferingV2(offeringKey: $offeringKey) {
									experienceUrl
									isAvailableToUser
								}
							}
							latestUsageForChargeElement(chargeElement: $chargeElement)
						}
					}
				}
			}
		`,
		{
			cloudId,
			hamsProductKey: productKey,
			chargeElement,
			offeringKey,
		},
	);

	const productEntitlementDetails = useProductEntitlementDetails();
	const entitlement = data.tenantContexts?.[0]?.entitlementInfo?.entitlement;
	const licensedUsers = entitlement?.latestUsageForChargeElement;
	const licensedUserLimit = productEntitlementDetails?.[applicationKey]?.unitCount;

	const canUpgrade =
		entitlement?.experienceCapabilities?.changeOfferingV2?.isAvailableToUser &&
		entitlement?.experienceCapabilities?.changeOfferingV2.experienceUrl;

	const { text, status } = useMemo((): {
		text: string;
		status: 'default' | 'warning' | 'danger';
	} => {
		if (!licensedUsers || !licensedUserLimit) {
			return { text: formatMessage(messages.upgrade), status: 'default' };
		}

		if (applicationKey === PRODUCT_DISCOVERY && licensedUsers >= licensedUserLimit) {
			return { text: formatMessage(messages.noCreatorsLeft), status: 'danger' };
		}

		if (applicationKey === PRODUCT_DISCOVERY && licensedUsers > 1) {
			return { text: formatMessage(messages.limitedCreatorsLeft), status: 'warning' };
		}

		if (applicationKey === SERVICE_DESK && licensedUsers >= licensedUserLimit) {
			return { text: formatMessage(messages.noAgentsLeft), status: 'danger' };
		}

		if (applicationKey === SERVICE_DESK && licensedUsers > 1) {
			return { text: formatMessage(messages.limitedAgentsLeft), status: 'warning' };
		}

		return { text: formatMessage(messages.upgrade), status: 'default' };
	}, [applicationKey, licensedUsers, licensedUserLimit, formatMessage]);

	const shouldSeeButton = useShouldSeeButton({
		applicationEdition,
		applicationKey,
		canUpgrade: Boolean(canUpgrade),
	});

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const buttonAnalyticsAttributes = useMemo(
		() => ({
			applicationEdition,
			applicationKey,
			appearance: status,
			licensedUsers,
			licensedUserLimit,
		}),
		[applicationEdition, applicationKey, status, licensedUsers, licensedUserLimit],
	);

	const handleOnClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'button',
			}),
			'editionAwarenessButton',
			buttonAnalyticsAttributes,
		);
	}, [createAnalyticsEvent, buttonAnalyticsAttributes]);

	useEffect(() => {
		if (!shouldSeeButton || !offeringKey) {
			return;
		}

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'viewed',
				actionSubject: 'button',
			}),
			'editionAwarenessButton',
			buttonAnalyticsAttributes,
		);
	}, [buttonAnalyticsAttributes, createAnalyticsEvent, offeringKey, shouldSeeButton]);

	const modalProps = useMemo(
		() => ({
			width: 968,
		}),
		[],
	);

	const entryPointProps = useMemo(
		() => ({
			applicationEdition,
			applicationKey,
		}),
		[applicationEdition, applicationKey],
	);

	const entryPointParams = useMemo(
		() => ({
			jiraApplicationKey: getApplicationKeyForProject(projectType),
			cloudId,
			productKey,
			offeringKey: offeringKey ?? '',
			chargeElement: ApplicationChargeElement[applicationKey],
		}),
		[applicationKey, cloudId, offeringKey, productKey, projectType],
	);

	if (!shouldSeeButton || !offeringKey) {
		return null;
	}

	return (
		<ModalEntryPointPressableTrigger
			entryPoint={editionAwarenessModalEntryPoint}
			entryPointParams={entryPointParams}
			entryPointProps={entryPointProps}
			modalProps={modalProps}
			interactionName="edition-awareness-modal"
			useInternalModal={false}
		>
			{({ ref }) => (
				<Button
					status={status}
					// This is how the examples in and tests are setup
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
					ref={ref as Ref<HTMLButtonElement>}
					onClick={handleOnClick}
				>
					{text}
				</Button>
			)}
		</ModalEntryPointPressableTrigger>
	);
};
