import React, { useEffect, useCallback } from 'react';
import { Section as SectionDI } from '@atlaskit/menu';
import { SpotlightTarget as SpotlightTargetDI } from '@atlaskit/onboarding';
import { recommendations as RecommendationsDI } from '@atlassian/jira-atlassian-navigation-recommendations/src/ui';
import { RecommendationSelector as RecommendationSelectorDI } from '@atlassian/jira-atlassian-navigation-recommendations/src/ui/recommendation-selector/index.tsx';
import { AuthenticationErrorBoundary as AuthenticationErrorBoundaryDI } from '@atlassian/jira-error-boundaries/src/ui/authentication-error-boundary/index.tsx';
import { JSErrorBoundary as JSErrorBoundaryDI } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/index.tsx';
import { useExperienceFail as useExperienceFailDI } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useFavoriteProjects as useFavoriteProjectsDI } from '@atlassian/jira-favorites-store';
import { useCreateProjectPermission as useCreateProjectPermissionDI } from '@atlassian/jira-nav-item-service/src/services/create-project-permisson';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import Placeholder from '@atlassian/jira-placeholder';
import {
	DEFAULT_LOAD_COUNT_FAV,
	PACKAGE_NAME,
	PROJECTS_SPOTLIGHT_KEY,
	EXPERIENCE_NAVIGATION_TOP_MENU,
} from '../../../common/constants';
import { MenuError as ErrorDI } from '../../../common/ui/menu/error/main.tsx';
import { MenuLayout as LayoutDI } from '../../../common/ui/menu/layout';
import { MenuSkeletonContent as MenuSkeletonContentDI } from '../../../common/ui/menu/skeleton/content/main.tsx';
import { MenuSkeletonFooter as MenuSkeletonFooterDI } from '../../../common/ui/menu/skeleton/footer/main.tsx';
import { Empty as EmptyDI } from './empty/main.tsx';
import { FavoriteContent as FavoriteContentDI } from './favorite-content/main.tsx';
import { Footer as FooterDI } from './footer/main.tsx';
import { LoadStatus as LoadStatusDI } from './load-status/main.tsx';
import { RecentContent as RecentContentDI } from './recent-content/main.tsx';
import type { MenuProps } from './types';

const EVENT_DATA = { id: MENU_ID.PROJECTS } as const;

export const Menu = ({
	AuthenticationErrorBoundary = AuthenticationErrorBoundaryDI,
	Empty = EmptyDI,
	Error = ErrorDI,
	FavoriteContent = FavoriteContentDI,
	Footer = FooterDI,
	JSErrorBoundary = JSErrorBoundaryDI,
	Layout = LayoutDI,
	LoadStatus = LoadStatusDI,
	RecentContent = RecentContentDI,
	RecommendationSelector = RecommendationSelectorDI,
	recommendations = RecommendationsDI,
	Section = SectionDI,
	SkeletonContent = MenuSkeletonContentDI,
	SkeletonFooter = MenuSkeletonFooterDI,
	SpotlightTarget = SpotlightTargetDI,
	testIdPrefix,
	// @ts-expect-error - TS2322 - Type 'HookFunction<State, BoundActions<State, { readonly fetchCreateProjectPermission: (baseUrl: string) => Action<State, void, void | Promise<void>>; }>, void>' is not assignable to type 'UseCreateProjectPermissionType'.
	useCreateProjectPermission = useCreateProjectPermissionDI,
	useExperienceFail = useExperienceFailDI,
	useFavorite = useFavoriteProjectsDI,
}: MenuProps) => {
	const [, { loadFavoriteProjects: loadFavorite }] = useFavorite();
	const [
		{ hasFetchedOnce: hasFetchedOncePermissions, isFetching },
		{ fetchCreateProjectPermission },
	] = useCreateProjectPermission();

	const onFail = useExperienceFail({ experience: EXPERIENCE_NAVIGATION_TOP_MENU });

	useEffect(
		() => () => {
			// Refresh favourites on close to update if user has toggled any item
			loadFavorite(DEFAULT_LOAD_COUNT_FAV);
		},
		[loadFavorite],
	);

	useEffect(() => {
		if (!hasFetchedOncePermissions && !isFetching) {
			fetchCreateProjectPermission();
		}
	}, [hasFetchedOncePermissions, isFetching, fetchCreateProjectPermission]);

	const testIdContent = testIdConcat(testIdPrefix, 'content');
	const testIdFooter = testIdConcat(testIdPrefix, 'footer');

	const errorFallback = useCallback(
		() => <Error testIdPrefix={testIdContent} />,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[testIdContent],
	);

	return (
		<SpotlightTarget name={PROJECTS_SPOTLIGHT_KEY}>
			<Layout isWide>
				<Section isScrollable>
					<JSErrorBoundary
						id={MENU_ID.PROJECTS}
						packageName={PACKAGE_NAME}
						fallback={errorFallback}
						withExperienceTracker
						extraEventData={EVENT_DATA}
						onError={onFail}
					>
						<AuthenticationErrorBoundary render={errorFallback}>
							<Empty testIdPrefix={testIdContent}>
								<Placeholder
									name="filters"
									fallback={<SkeletonContent testIdPrefix={testIdContent} />}
								>
									<LoadStatus shouldThrowError />
									<FavoriteContent testIdPrefix={testIdContent} />
									<RecentContent testIdPrefix={testIdContent} />
								</Placeholder>
							</Empty>
						</AuthenticationErrorBoundary>
					</JSErrorBoundary>
				</Section>
				<RecommendationSelector recommendations={recommendations} />
				<Section hasSeparator>
					<Placeholder name="footer" fallback={<SkeletonFooter testIdPrefix={testIdFooter} />}>
						<LoadStatus />
						<Footer testIdPrefix={testIdFooter} />
					</Placeholder>
				</Section>
			</Layout>
		</SpotlightTarget>
	);
};
