import React, { useCallback } from 'react';
import { Box } from '@atlaskit/primitives';
import { ChatOpenerSubscriber } from '@atlassian/conversation-assistant';
import { RovoEntitlementSetter } from '@atlassian/conversation-assistant-entitlement';
import { ChatContextSubscriber } from '@atlassian/conversation-assistant-store';
import { ConversationAssistantButton as AssistantButton } from '@atlassian/conversation-assistant-ui-components';
import {
	CONVERSATION_ASSISTANT_CONTAINER_ID,
	CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
} from '@atlassian/conversation-assistant/utils';
import {
	useActiveRightSidebarState,
	useRightSidebarController,
} from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/right-sidebar-controller/index.tsx';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useRovoEntitlementResource } from '@atlassian/jira-router-resources-rovo-entitlement/src/services/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { NAVIGATION_ITEM_ID } from '../../common/constants';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary';

const ConversationAssistantButton = () => {
	const [_topMenusValue, { off: closeAllMenus }] = useTopMenus();
	const { openRightSidebar, closeCurrentRightSidebar } = useRightSidebarController();
	const { panelId, isCollapsed } = useActiveRightSidebarState() || {};
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();

	const isActive = panelId === CONVERSATION_ASSISTANT_CONTAINER_ID && !isCollapsed;

	const onButtonClickOld = useCallback(() => {
		if (isActive) {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'navigationItem',
				}),
				'aiMate',
				{ opened: isCollapsed },
			);
			closeCurrentRightSidebar();
		} else {
			openRightSidebar(
				CONVERSATION_ASSISTANT_CONTAINER_ID,
				CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
				false,
				true,
			);
		}
		closeAllMenus();
	}, [
		closeAllMenus,
		closeCurrentRightSidebar,
		createAnalyticsEvent,
		isActive,
		isCollapsed,
		openRightSidebar,
	]);

	const onButtonClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'navigationItem',
			}),
			'aiMate',
			{ opened: !isActive },
		);

		isActive
			? closeCurrentRightSidebar()
			: openRightSidebar(
					CONVERSATION_ASSISTANT_CONTAINER_ID,
					CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
					false,
					true,
				);

		closeAllMenus();
	}, [isActive, closeAllMenus, closeCurrentRightSidebar, openRightSidebar, createAnalyticsEvent]);

	const buttonRender = (
		<Box
			as="span"
			paddingInlineStart={getWillShowNav4() ? undefined : 'space.050'}
			testId="atlassian-navigation.ui.conversation-assistant.app-navigation-ai-mate"
			role={
				// This element is a direct descendant of the top nav actions list, so needs the listitem role.
				// While the button is internally wrapped in a listitem, screen readers do not pick this up due to multiple wrapper elements.
				getWillShowNav4() ? 'listitem' : undefined
			}
		>
			<AssistantButton
				onClick={getWillShowNav4() ? onButtonClick : onButtonClickOld}
				isActive={isActive}
				siteId={cloudId}
				productKey="jira"
			/>
		</Box>
	);

	return (
		<>
			{buttonRender}
			<ChatContextSubscriber />
			<ChatOpenerSubscriber
				onTriggerOpeningChat={() => {
					if (!isActive) {
						openRightSidebar(
							CONVERSATION_ASSISTANT_CONTAINER_ID,
							CONVERSATION_ASSISTANT_CONTAINER_WIDTH,
							false,
							true,
						);
					}
				}}
			/>
		</>
	);
};

export const ConversationAssistant = () => {
	const { data } = useRovoEntitlementResource();

	const shouldShowConversationAssistantButton = data?.isRovoEnabled ?? false;

	const buttonRender = shouldShowConversationAssistantButton ? (
		<ConversationAssistantButton />
	) : null;

	return (
		<TopLevelErrorBoundary id={NAVIGATION_ITEM_ID.CONVERSATION_ASSISTANT}>
			{buttonRender}
			<RovoEntitlementSetter isRovoEnabled={shouldShowConversationAssistantButton} />
		</TopLevelErrorBoundary>
	);
};
