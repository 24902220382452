import noop from 'lodash/noop';
import {
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	toProjectType,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { ff } from '@atlassian/jira-feature-flagging';
import {
	type ApplicationEdition,
	getEditionForProject,
	type ApplicationEditions,
	STANDARD_EDITION,
} from '@atlassian/jira-shared-types/src/edition.tsx';

export type SupportedProjectType =
	| typeof SERVICE_DESK_PROJECT
	| typeof PRODUCT_DISCOVERY_PROJECT
	| typeof SOFTWARE_PROJECT;

const TRIAL_END_LOCK_SCREEN_JSM_STATSIG_FLAG = 'jsm_trial_end_lock_screen_experiment';
const TRIAL_END_LOCK_SCREEN_JPD_STATSIG_FLAG = 'jpd_trial_end_lock_screen_experiment';
const TRIAL_END_LOCK_SCREEN_JSW_STATSIG_FLAG = 'jsw_trial_end_lock_screen_experiment';

const TRIAL_END_LOCK_SCREEN_JSM_FF = 'jsm-trial-end-lock-screen-experiment_nowmr';
const TRIAL_END_LOCK_SCREEN_JPD_FF = 'jpd-trial-end-lock-screen-experiment';
const TRIAL_END_LOCK_SCREEN_JSW_FF = 'jsw-trial-end-lock-screen-experiment_ieq79';

export const SUPPORTED_PROJECT_TYPES: SupportedProjectType[] = [
	SERVICE_DESK_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SOFTWARE_PROJECT,
];

type ProjectFlagType = {
	gatingFlagType: 'ff' | 'fg';
	gatingFlag: string;
	statsig: string;
	showForUserUsageUnderLimit?: boolean;
};

type ProjectFlagsType = {
	[projectType in SupportedProjectType]: {
		[applicationEdition in ApplicationEdition]?: ProjectFlagType;
	};
};

const PROJECT_FLAGS: ProjectFlagsType = {
	[SERVICE_DESK_PROJECT]: {
		[STANDARD_EDITION]: {
			gatingFlagType: 'ff',
			gatingFlag: TRIAL_END_LOCK_SCREEN_JSM_FF,
			statsig: TRIAL_END_LOCK_SCREEN_JSM_STATSIG_FLAG,
		},
	},
	[PRODUCT_DISCOVERY_PROJECT]: {
		[STANDARD_EDITION]: {
			gatingFlagType: 'ff',
			gatingFlag: TRIAL_END_LOCK_SCREEN_JPD_FF,
			statsig: TRIAL_END_LOCK_SCREEN_JPD_STATSIG_FLAG,
		},
	},
	[SOFTWARE_PROJECT]: {
		[STANDARD_EDITION]: {
			gatingFlagType: 'ff',
			gatingFlag: TRIAL_END_LOCK_SCREEN_JSW_FF,
			statsig: TRIAL_END_LOCK_SCREEN_JSW_STATSIG_FLAG,
		},
	},
};

export const getStatSigCohortAndExposureFunction = ({
	projectType,
	edition,
}: {
	projectType: SupportedProjectType;
	edition: ApplicationEdition | undefined;
}) => {
	let expConfig;
	let fireExpExperimentExposure;

	switch (projectType) {
		case SERVICE_DESK_PROJECT:
			if (edition === STANDARD_EDITION) {
				[expConfig, fireExpExperimentExposure] = UNSAFE_noExposureExp(
					'jsm_trial_end_lock_screen_experiment',
				);
			}
			break;
		case PRODUCT_DISCOVERY_PROJECT:
			if (edition === STANDARD_EDITION) {
				[expConfig, fireExpExperimentExposure] = UNSAFE_noExposureExp(
					'jpd_trial_end_lock_screen_experiment',
				);
			}
			break;
		case SOFTWARE_PROJECT:
			if (edition === STANDARD_EDITION) {
				[expConfig, fireExpExperimentExposure] = UNSAFE_noExposureExp(
					'jsw_trial_end_lock_screen_experiment',
				);
			}
			break;
		default:
			break;
	}

	return {
		cohort: expConfig ? expConfig.get('cohort', 'not-enrolled') : 'not-enrolled',
		fireExpExperimentExposure: fireExpExperimentExposure ?? noop,
	};
};

export const isSupportedProjectType = (value?: string | null): value is SupportedProjectType => {
	const projectType = toProjectType(value);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	return !!projectType && SUPPORTED_PROJECT_TYPES.includes(projectType as SupportedProjectType);
};

export const isTrialEndLockScreenEnabledForProject = ({
	projectType,
	edition,
}: {
	projectType: SupportedProjectType;
	edition: ApplicationEdition | undefined;
}) => {
	const { cohort } = getStatSigCohortAndExposureFunction({ projectType, edition });

	switch (projectType) {
		case SERVICE_DESK_PROJECT:
			if (edition === STANDARD_EDITION) {
				return ff('jsm-trial-end-lock-screen-experiment_nowmr') && cohort !== 'not-enrolled';
			}
			break;
		case PRODUCT_DISCOVERY_PROJECT:
			if (edition === STANDARD_EDITION) {
				return ff('jpd-trial-end-lock-screen-experiment') && cohort !== 'not-enrolled';
			}
			break;
		case SOFTWARE_PROJECT:
			if (edition === STANDARD_EDITION) {
				return ff('jsw-trial-end-lock-screen-experiment_ieq79') && cohort !== 'not-enrolled';
			}
			break;
		default:
			return false;
	}

	return false;
};

export const isTrialEndLockScreenEnabledForAnyProjectType = (appEditions: ApplicationEditions) =>
	SUPPORTED_PROJECT_TYPES.some((projectType) => {
		const edition = projectType && appEditions && getEditionForProject(projectType, appEditions);
		return isTrialEndLockScreenEnabledForProject({ projectType, edition });
	});

export const getShowForUserUsageUnderLimit = ({
	projectType,
	edition,
}: {
	projectType: SupportedProjectType;
	edition: ApplicationEdition | undefined;
}) => !!edition && !!PROJECT_FLAGS[projectType][edition]?.showForUserUsageUnderLimit;
