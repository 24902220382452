import React, { useCallback } from 'react';
import { useIsImportersOnGlobalNavEnabled } from '@atlassian/jira-importers-discovery/src/controllers/use-is-importers-on-global-nav-enabled/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useCreateProjectPermission } from '@atlassian/jira-nav-item-service/src/services/create-project-permisson';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { ProjectCreateDrawerConsumer } from '@atlassian/jira-project-create-drawer-context';
import type { OpenDrawerMethod } from '@atlassian/jira-project-create-drawer-context/src/types';
import { projectsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/services/projects/index.tsx';
import { useSwitchModals } from '@atlassian/jira-switch-modals-controller';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { MODAL_ID } from '../../../../common/constants';
import { MenuItem } from '../../../../common/ui/menu/item';
import messages from './messages';
import type { FooterProps } from './types';

export const Footer = ({ testIdPrefix }: FooterProps) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const [, { on: openProjectCreateDrawer }] = useSwitchModals(MODAL_ID.PROJECT_CREATE);
	const [{ canCreateProject }] = useCreateProjectPermission();
	const testId = testIdConcat(testIdPrefix, 'items');
	const shouldShowImportButton = useIsImportersOnGlobalNavEnabled();

	const handleImportButtonClick = useCallback((open: OpenDrawerMethod) => {
		open({
			source: 'importDiscoveryGlobalNavDropdown',
			isImportProjectOpenInitially: true,
		});
	}, []);

	return (
		<span data-testid={testId}>
			<MenuItem
				analytics={{
					actionSubjectId: 'projectsNavigationMenuItem',
					menuItemId: 'viewAll',
					menuItemType: 'action',
					position: 0,
				}}
				href="/jira/projects"
				testId={testIdConcat(testId, 'item-view-all')}
				to={projectsDirectoryRoute}
			>
				{formatMessage(messages.viewAll)}
			</MenuItem>
			{!isAnonymous && canCreateProject && (
				<>
					<MenuItem
						analytics={{
							actionSubjectId: 'projectsNavigationMenuItem',
							menuItemId: 'create',
							menuItemType: 'action',
							position: 1,
						}}
						onClick={openProjectCreateDrawer}
						testId={testIdConcat(testId, 'item-create')}
					>
						{formatMessage(messages.createProject)}
					</MenuItem>
					{shouldShowImportButton && (
						<ProjectCreateDrawerConsumer>
							{({ methods: { open } }) => (
								<MenuItem
									analytics={{
										actionSubjectId: 'projectsNavigationMenuItem',
										menuItemId: 'create',
										menuItemType: 'action',
										position: 2,
									}}
									onClick={() => handleImportButtonClick(open)}
									testId={testIdConcat(testId, 'item-import')}
								>
									{formatMessage(messages.importProject)}
								</MenuItem>
							)}
						</ProjectCreateDrawerConsumer>
					)}
				</>
			)}
		</span>
	);
};
