import React, { type MouseEvent, useCallback } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { FormattedMessage } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { usePlansItems } from '@atlassian/jira-plans-item-store';
import { useCreateNewPlanWizardDrawer } from '@atlassian/jira-portfolio-3-create-new-plan-wizard-drawer/src/controllers';
import { useCreateSamplePlan } from '@atlassian/jira-portfolio-3-create-sample-plan/src/controllers';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { MenuInfo } from '../../../../../common/ui/menu/info/main.tsx';
import emptyImg from './assets/plans-empty.svg';
import messages from './messages';
import type { EmptyProps } from './types';

const EmptyWithCTAs = ({ testIdPrefix }: EmptyProps) => {
	const [{ items }] = usePlansItems();
	const [, { openDrawer: openCreateNewPlanWizardDrawer }] = useCreateNewPlanWizardDrawer();
	const [, { open: openCreateSamplePlanModal }] = useCreateSamplePlan();

	const handleRequestCreateSamplePlan = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(e: MouseEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'navigationCreateSamplePlan');
			openCreateSamplePlanModal();
		},
		[openCreateSamplePlanModal],
	);

	const handleRequestCreatePlan = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(e: MouseEvent<any>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'navigationCreatePlan');
			openCreateNewPlanWizardDrawer();
		},
		[openCreateNewPlanWizardDrawer],
	);

	const createPlan = items?.links?.createPlan;
	const createSamplePlan = items?.links?.createSamplePlan;
	const hasButtons = Boolean(createPlan?.isVisible || createSamplePlan?.isVisible);

	const text = (
		<>
			<p>
				<FormattedMessage {...messages.plansText} />
			</p>
			{hasButtons && (
				<Box xcss={buttonGroupStyles}>
					{createSamplePlan?.isVisible && (
						<Button onClick={handleRequestCreateSamplePlan}>
							<FormattedMessage {...messages.createDemoPlan} />
						</Button>
					)}
					{createPlan?.isVisible && (
						<Button appearance="primary" onClick={handleRequestCreatePlan}>
							<FormattedMessage {...messages.createPlan} />
						</Button>
					)}
				</Box>
			)}
		</>
	);
	return (
		<MenuInfo
			imgAltText=""
			imgSrc={emptyImg}
			testId={testIdConcat(testIdPrefix, 'empty')}
			text={text}
		/>
	);
};

export default EmptyWithCTAs;

const buttonGroupStyles = xcss({
	marginTop: 'space.200',
	display: 'flex',
	gap: 'space.100',
	justifyContent: 'center',
});
