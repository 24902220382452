import { useEffect, useRef, useState } from 'react';
import { useAddon } from '@atlassian/jira-addon-store';
import { usePersonalizationProvider } from '@atlassian/jira-business-sample-project/src/services/personalization/index.tsx';
import { useFavoriteActions } from '@atlassian/jira-favorites-store';
import { DEFAULT_LOAD_COUNT_FAV } from '@atlassian/jira-favorites-store/src/constants.tsx';
import { useForgeGlobalItems } from '@atlassian/jira-forge-global-items-store';
import { useCreateProjectPermissionActions } from '@atlassian/jira-nav-item-service/src/services/create-project-permisson';
import { useRecentActions } from '@atlassian/jira-recents-store';
import usePageLoadedSubscriber from '@atlassian/jira-spa-state-controller/src/components/page-loaded-state/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';

export const InitialDataLoader = () => {
	const [, { loadRecentAll }] = useRecentActions();
	const [, { loadFavoriteAll }] = useFavoriteActions();
	const [, { fetchCreateProjectPermission }] = useCreateProjectPermissionActions();
	const [, { load: loadForgeItem }] = useForgeGlobalItems();
	const [, { load: loadAddons }] = useAddon();
	const [, { fetchTraits }] = usePersonalizationProvider();

	const hasFetchedAll = useRef(false);
	const [{ hasLoaded }] = usePageLoadedSubscriber();
	const [hasTimedOut, setHasTimedOut] = useState(false);

	const accountId = useAccountId();
	const activationId = useActivationId();
	const cloudId = useCloudId();
	const locale = useLocale();

	useEffect(() => {
		if ((hasLoaded || hasTimedOut) && !hasFetchedAll.current) {
			loadRecentAll();
			loadFavoriteAll(DEFAULT_LOAD_COUNT_FAV);
			loadForgeItem({ cloudId, activationId, locale });
			fetchCreateProjectPermission();
			loadAddons();
			hasFetchedAll.current = true;
		}
	}, [
		hasLoaded,
		hasTimedOut,
		hasFetchedAll,
		loadRecentAll,
		loadFavoriteAll,
		fetchCreateProjectPermission,
		loadForgeItem,
		loadAddons,
		accountId,
		fetchTraits,
		cloudId,
		activationId,
		locale,
	]);

	// given not all pages use SpaStateReady yet, we fallback to timeout
	// so we preload nav items anyway after some time
	useEffect(() => {
		let t: ReturnType<typeof setTimeout>;
		if (!hasLoaded) t = setTimeout(() => setHasTimedOut(true), 10 * 1000);
		return () => clearTimeout(t);
	}, [hasLoaded, setHasTimedOut]);

	return null;
};
