import { useCallback, useEffect } from 'react';
import queryString from 'query-string';
import {
	type CmdbObjectId,
	type CmdbObjectTypeId,
	toCmdbObjectId,
	toCmdbObjectTypeId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { useRouter, useRouterActions, useQueryParam } from '@atlassian/react-resource-router';

// As defined in react-resource-router, but not exported
export type HistoryUpdateType = 'push' | 'replace';

export type SchemaViewMode = 'object' | 'attribute';
export type ObjectViewMode = 'details' | 'list';
export const SCHEMA_PAGE_QUERY_PARAMS = {
	// Values are 'object' or 'attribute'
	schemaMode: 'mode',
	// Values are 'list' or undefined
	objectViewMode: 'view',
	// Both of these params are supported to set the object type ID. 'typeId' is preferred and is the one that is updated.
	// 'objecttypeid' is only used on initial load to support customers who have bookmarked this URL.
	objectTypeId: 'typeId',
	fallbackObjectTypeId: 'objecttypeid',
	objectId: 'objectId',
} as const;

type QueryParamSetter<T> = (newValue: T, updateType?: HistoryUpdateType) => void;

type QueryParams = {
	[paramName: string]: string | number | boolean | null | undefined;
};

export const useUpdateQueryParams = () => {
	const { push } = useRouterActions();
	const [{ location }] = useRouter();

	const updateQueryParams = useCallback(
		(queryParams: QueryParams = {}): string => {
			const { pathname, search } = location;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const updatedQueryParams: Record<string, any> = {};
			Object.keys(queryParams).forEach((key: string) => {
				updatedQueryParams[key] = queryParams[key] ?? undefined;
			});

			return `${pathname}?${queryString.stringify({
				...queryString.parse(search),
				...updatedQueryParams,
			})}`;
		},
		[location],
	);

	const pushRouterAction = useCallback(
		(path: string) => {
			push(path);
		},
		[push],
	);

	return [{ updateQueryParams, pushRouterAction }] as const;
};

export const useObjectTypeIdQueryParam = (): [
	CmdbObjectTypeId | null,
	QueryParamSetter<CmdbObjectTypeId | null>,
] => {
	const [objectTypeId, setObjectTypeId] = useQueryParam(SCHEMA_PAGE_QUERY_PARAMS.objectTypeId);
	const [fallbackObjectTypeId, setFallbackObjectTypeId] = useQueryParam(
		SCHEMA_PAGE_QUERY_PARAMS.fallbackObjectTypeId,
	);

	useEffect(() => {
		if (fallbackObjectTypeId !== undefined) {
			setObjectTypeId(toCmdbObjectTypeId(fallbackObjectTypeId), 'replace');
			setFallbackObjectTypeId(undefined, 'replace');
		}
	}, [fallbackObjectTypeId, setObjectTypeId, setFallbackObjectTypeId]);

	const nullableMain = objectTypeId !== undefined ? toCmdbObjectTypeId(objectTypeId) : null;
	const nullableFallback =
		fallbackObjectTypeId !== undefined ? toCmdbObjectTypeId(fallbackObjectTypeId) : null;

	const setter: QueryParamSetter<CmdbObjectTypeId | null> = (newObjectTypeId, updateType) => {
		setObjectTypeId(newObjectTypeId !== null ? newObjectTypeId : undefined, updateType);
	};

	return [nullableMain !== null ? nullableMain : nullableFallback, setter];
};

export const useObjectIdQueryParam = (): [
	CmdbObjectId | null,
	QueryParamSetter<CmdbObjectId | null>,
] => {
	const [objectId, setObjectId] = useQueryParam(SCHEMA_PAGE_QUERY_PARAMS.objectId);

	const nullableObjectId = objectId !== undefined ? toCmdbObjectId(objectId) : null;
	const setter: QueryParamSetter<CmdbObjectId | null> = (newObjectId, updateType) => {
		setObjectId(newObjectId !== null ? newObjectId : undefined, updateType);
	};

	return [nullableObjectId, setter];
};

export const useSchemaViewModeQueryParam = (): [
	SchemaViewMode,
	QueryParamSetter<SchemaViewMode>,
] => {
	const [rawParamValue, setSchemaMode] = useQueryParam(SCHEMA_PAGE_QUERY_PARAMS.schemaMode);
	const schemaMode: SchemaViewMode = rawParamValue === 'attribute' ? 'attribute' : 'object';
	return [schemaMode, setSchemaMode];
};

export const useObjectViewModeQueryParam = (): [
	ObjectViewMode,
	QueryParamSetter<ObjectViewMode>,
] => {
	const [rawParamValue, setObjectViewMode] = useQueryParam(SCHEMA_PAGE_QUERY_PARAMS.objectViewMode);
	const objectViewMode: ObjectViewMode = rawParamValue === 'list' ? 'list' : 'details';
	const setter: QueryParamSetter<ObjectViewMode> = (newValue, updateMode) => {
		setObjectViewMode(newValue === 'list' ? 'list' : undefined, updateMode);
	};
	return [objectViewMode, setter];
};
