import React, { useEffect } from 'react';
import { Text } from '@atlaskit/primitives';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { useFavoriteDashboards as useFavorite } from '@atlassian/jira-favorites-store';
import { useIntl } from '@atlassian/jira-intl';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { useRecentDashboards as useRecent } from '@atlassian/jira-recents-store';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { EXPERIENCE_NAVIGATION_TOP_MENU } from '../../../../common/constants';
import { MenuInfo } from '../../../../common/ui/menu/info/main.tsx';
import { useDashboardNavItems as useItems } from '../../../../controllers/items';
import defaultEmptyImg from './assets/dashboards-empty.svg';
import messages from './messages';
import type { EmptyProps } from './types';

const learnMoreHref =
	'https://confluence.atlassian.com/jiracorecloud/configuring-dashboards-765593585.html';

export const Empty = ({ children, testIdPrefix }: EmptyProps) => {
	const { formatMessage } = useIntl();
	const isAnonymous = useIsAnonymous();
	const onSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
	});
	const text = (
		<>
			<div>
				<Text as="p">{formatMessage(messages.emptyText)}</Text>
			</div>
			<div>
				<a href={learnMoreHref} target="_blank" rel="noopener noreferrer">
					{formatMessage(messages.learnMore)}
				</a>
			</div>
		</>
	);

	const [
		{
			hasSuccessOnce: hasSuccessOnceFavorite,
			isFetching: isFetchingFavorite,
			fetchError: fetchErrorFavorite,
		},
	] = useFavorite();
	const [
		{
			hasSuccessOnce: hasSuccessOnceRecent,
			isFetching: isFetchingRecent,
			fetchError: fetchErrorRecent,
		},
	] = useRecent();
	const { favoriteItems, recentItems } = useItems();

	const notEmpty =
		favoriteItems.length || //  should show fav items
		recentItems.length || // should show recent items
		(isFetchingFavorite && !hasSuccessOnceFavorite) || // should show fav skeleton
		(isFetchingRecent && !hasSuccessOnceRecent) || // should show recent skeleton
		fetchErrorFavorite || // should show error
		fetchErrorRecent; // should show error

	const shouldShowEmptyForAnonymous = isAnonymous;

	useEffect(() => {
		const isSuccess = hasSuccessOnceFavorite && hasSuccessOnceRecent;
		if (isSuccess) {
			onSuccess();
		}
	}, [hasSuccessOnceFavorite, hasSuccessOnceRecent, onSuccess]);

	const testId = testIdConcat(testIdPrefix, 'empty');

	return notEmpty && !shouldShowEmptyForAnonymous ? (
		<>{children}</>
	) : (
		<MenuInfo imgSrc={defaultEmptyImg} testId={testId} text={text} />
	);
};
